
import { Component, Prop, Vue } from "vue-property-decorator";
import { RestRootItem } from "@coveo/levelup-protocol";
import Lock from "@/assets/lock.svg";

@Component({
  components: {
    Lock,
  },
})
export default class ItemLock extends Vue {
  @Prop({ required: true }) private item!: RestRootItem;
}
