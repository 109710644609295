import { v4 as uuidv4 } from "uuid";

// eslint-disable-next-line no-unused-vars
export type EventHandler = (...args: unknown[]) => void;
type EventOwner = { [event: string]: EventHandler[] };
const eventOwners: { [owner: string]: EventOwner } = {};

function userChangeEvent(): string {
  return `user-change`;
}

function languageChangeEvent(): string {
  return `language-change`;
}

function teamsChangeEvent(): string {
  return `teams-change`;
}

function skillPathChangeEvent(skillPathId: string): string {
  return `skill-path-change-${skillPathId}`;
}

function roadmapChangeEvent(roadmapId: string): string {
  return `roadmap-change-${roadmapId}`;
}

function courseChangeEvent(courseId: string): string {
  return `course-change-${courseId}`;
}

function lessonChangeEvent(courseId: string, lessonId: string): string {
  return `lesson-change-${courseId}|${lessonId}`;
}

function eventCollectionsChangeEvent(): string {
  return `event-collections-change`;
}

function eventsChangeEvent(): string {
  return `events-change`;
}

function sessionsChangeEvent(): string {
  return `sessions-change`;
}

function assignmentChangeEvent(itemId: string): string {
  return `assignment-change-${itemId}`;
}

function starredItemsChangeEvent(): string {
  return `starred-items-change`;
}

function promotionalBannerChangeEvent(): string {
  return `promotional-banner-change`;
}

function on(owner: string, event: string, handler: EventHandler): void {
  let eventOwner = eventOwners[owner];
  if (eventOwner === undefined) {
    eventOwner = {};
    eventOwners[owner] = eventOwner;
  }

  let eventHandlers = eventOwner[event];
  if (eventHandlers === undefined) {
    eventHandlers = [];
    eventOwner[event] = eventHandlers;
  }

  eventHandlers.push(handler);
}

function emit(event: string, ...args: unknown[]): Promise<unknown> {
  const promises: Promise<void>[] = [];

  Object.keys(eventOwners).forEach((o) => {
    const owner = eventOwners[o];
    const handlers = owner[event];
    if (handlers !== undefined) {
      handlers.forEach((h) => {
        const res = h(...args);
        promises.push(Promise.resolve(res));
      });
    }
  });

  return Promise.all<unknown>(promises);
}

function unregisterAllHandlers(owner: string): void {
  delete eventOwners[owner];
}

export const EventBus = {
  emitUserChange: async function (): Promise<unknown> {
    return emit(userChangeEvent());
  },

  emitLanguageChange: async function (): Promise<unknown> {
    return emit(languageChangeEvent());
  },

  emitTeamsChange: async function (): Promise<unknown> {
    return emit(teamsChangeEvent());
  },

  emitSkillPathChange: async function (skillPathId: string): Promise<unknown> {
    return Promise.all([emit(userChangeEvent()), emit(skillPathChangeEvent(skillPathId))]);
  },

  emitRoadmapChange: async function (roadmapId: string): Promise<unknown> {
    return Promise.all([emit(userChangeEvent()), emit(roadmapChangeEvent(roadmapId))]);
  },

  emitCourseChange: async function (courseId: string): Promise<unknown> {
    return Promise.all([emit(userChangeEvent()), emit(courseChangeEvent(courseId))]);
  },

  emitLessonChange: async function (courseId: string, lessonId: string): Promise<unknown> {
    return Promise.all([
      emit(userChangeEvent()),
      emit(courseChangeEvent(courseId)),
      emit(lessonChangeEvent(courseId, lessonId)),
    ]);
  },

  emitEventsChange: async function (): Promise<unknown> {
    return Promise.all([emit(userChangeEvent()), emit(eventsChangeEvent())]);
  },

  emitSessionChange: async function (): Promise<unknown> {
    return Promise.all([emit(userChangeEvent()), emit(eventsChangeEvent()), emit(sessionsChangeEvent())]);
  },

  emitAssignmentChange: async function (itemId: string): Promise<unknown> {
    return Promise.all([emit(userChangeEvent()), emit(assignmentChangeEvent(itemId))]);
  },

  emitStarredItemsChange: async function (): Promise<unknown> {
    return emit(starredItemsChangeEvent());
  },

  emitPromotionalBannerChange: async function (): Promise<unknown> {
    return emit(promotionalBannerChangeEvent());
  },
};

export class EventWatcher {
  private uuid = uuidv4();

  watchTeams(handler: () => unknown): void {
    on(this.uuid, teamsChangeEvent(), handler);
  }

  watchUser(handler: () => unknown): void {
    on(this.uuid, userChangeEvent(), handler);
  }

  watchLanguage(handler: () => unknown): void {
    on(this.uuid, languageChangeEvent(), handler);
  }

  watchSkillPath(skillPathId: string, handler: () => unknown): void {
    on(this.uuid, skillPathChangeEvent(skillPathId), handler);
  }

  watchRoadmap(roadmapId: string, handler: () => unknown): void {
    on(this.uuid, roadmapChangeEvent(roadmapId), handler);
  }

  watchCourse(courseId: string, handler: () => unknown): void {
    on(this.uuid, courseChangeEvent(courseId), handler);
  }

  watchLesson(courseId: string, lessonId: string, handler: () => unknown): void {
    on(this.uuid, lessonChangeEvent(courseId, lessonId), handler);
  }

  watchEventCollections(handler: () => unknown): void {
    on(this.uuid, eventCollectionsChangeEvent(), handler);
  }

  watchEvents(handler: () => unknown): void {
    on(this.uuid, eventsChangeEvent(), handler);
  }

  watchSessions(handler: () => unknown): void {
    on(this.uuid, sessionsChangeEvent(), handler);
  }

  watchAssignments(itemId: string, handler: () => unknown): void {
    on(this.uuid, assignmentChangeEvent(itemId), handler);
  }

  watchStarredItems(handler: () => unknown): void {
    on(this.uuid, starredItemsChangeEvent(), handler);
  }

  watchPromotionalBanner(handler: () => unknown): void {
    on(this.uuid, promotionalBannerChangeEvent(), handler);
  }

  destroy(): void {
    unregisterAllHandlers(this.uuid);
  }
}
