
import { Component, Vue } from "vue-property-decorator";
import CoveoWhite from "@/assets/coveo-white.svg";

@Component({
  components: {
    CoveoWhite,
  },
})
export default class CoveoLogo extends Vue {}
