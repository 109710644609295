
import { Component, Prop, Vue } from "vue-property-decorator";
import { RestBadge } from "@coveo/levelup-protocol";
import LoadedContent from "@/components/LoadedContent.vue";
import { localizeStringByContext } from "@coveo/levelup-protocol";
import { context } from "@/globals";

@Component({ components: { LoadedContent } })
export default class UserBadge extends Vue {
  private localizeString = localizeStringByContext;
  private context = context;
  @Prop() private badge!: RestBadge;
  private imageLoading = true;

  created(): void {
    const img = new Image();
    img.addEventListener("load", () => (this.imageLoading = false));
    img.src = this.badge.badge;
  }
}
