
import { Component, Vue } from "vue-property-decorator";
import { RestListOfAssignedItems } from "@coveo/levelup-protocol";
import ListOfRootItems from "@/components/items/ListOfRootItems.vue";
import { ItemType, RestEvent, RestListOfCourses, RestListOfRootItems, SortRootItemsBy } from "@coveo/levelup-protocol";
import UserAchievements from "@/components/users/UserAchievements.vue";
import UserTeams from "@/components/teams/UserTeams.vue";
import InfoBox from "@/components/InfoBox.vue";
import { calls, context } from "@/globals";
import HeroImage from "@/assets/hero/hero-home.svg";
import ItemMosaic from "@/components/items/ItemMosaic.vue";
import CarouselOfItemCards from "@/components/items/CarouselOfItemCards.vue";
import { EventWatcher } from "@/event-bus";
import Loading from "@/components/Loading.vue";
import navigation from "@/navigation";
import BackgroundCircle from "@/components/BackgroundCircle.vue";
import BackgroundLenses from "@/components/BackgroundLenses.vue";
import FullWidthSection from "@/components/FullWidthSection.vue";
import _ from "lodash";

@Component({
  components: {
    BackgroundLenses,
    BackgroundCircle,
    Loading,
    UserTeams,
    UserAchievements,
    ListOfRootItems,
    InfoBox,
    ItemMosaic,
    HeroImage,
    CarouselOfItemCards,
    FullWidthSection,
  },
})
export default class Home extends Vue {
  private watcher = new EventWatcher();

  assignments: RestListOfAssignedItems = {
    assignments: [],
    totalCount: 0,
  };
  context = context;
  closestEventInEachCollection!: RestEvent[];
  error = false;
  navigation = navigation;
  newItems!: RestListOfRootItems;
  ready = false;
  startedCourses: RestListOfCourses = {
    courses: [],
    totalCount: 0,
  };
  defaultCourses: RestListOfCourses = {
    courses: [],
    totalCount: 0,
  };
  userName: string | null = null;
  maxCarouselItems = 32;

  async created(): Promise<void> {
    try {
      this.startedCourses = await calls.listCourses({
        page: 0,
        pageSize: this.maxCarouselItems,
        onlyStarted: true,
        includeArchived: true,
      });
      this.newItems = await calls.listRootItems({
        page: 0,
        pageSize: 5,
        sortBy: SortRootItemsBy.DateAddedDescending,
        types: [ItemType.SkillPath, ItemType.Roadmap, ItemType.Course],
        onlyPublic: true,
      });

      if (this.startedCourses.courses.length == 0) {
        this.defaultCourses = await calls.listCourses({ page: 0, pageSize: 5 });
      }

      const events = await calls.listEvents({ onlyNotFinished: true });
      const collections = (await calls.listEventCollections()).eventCollections;
      const [eventsWithCollection, eventsWithoutCollection] = _.partition(events.events, (e) =>
        collections.some((c) => c.events.includes(e.id)),
      );
      this.closestEventInEachCollection = _.sortBy(
        [
          ..._.uniq(
            _.compact(
              collections.map((c) => {
                return eventsWithCollection.find((e) => c.events.includes(e.id));
              }),
            ),
          ),
          ...eventsWithoutCollection,
        ],
        (e) => e.start,
      );

      this.userName = context.user?.firstName ?? context.user?.displayName ?? null;
      this.ready = true;
    } catch {
      this.error = true;
    }
  }

  beforeDestroy(): void {
    this.watcher.destroy();
  }

  get browseNewReleasesUrl(): string {
    return `${navigation.getSearchUrl()}#numberOfResults=9&sortCriteria=date%20descending`;
  }

  get eventCarouselColumns(): number {
    return this.$vuetify.breakpoint.mdAndDown || this.closestEventInEachCollection.length < 2 ? 1 : 2;
  }

  get eventDescriptionStyle(): string {
    return this.$vuetify.breakpoint.xlOnly ? "max-width: 50rem;" : "max-width: 32rem";
  }
}
