
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ArrowRight from "@/assets/arrow-right.svg";
import ArrowLeft from "@/assets/arrow-left.svg";
import assert from "assert";

@Component({ components: { ArrowRight, ArrowLeft } })
export default class Carousel extends Vue {
  @Prop({ required: true }) cols!: number;
  @Prop({ required: true }) items!: unknown[];
  @Prop({ default: "24px" }) gap!: string;
  @Prop({ default: true }) showDots!: boolean;
  private resizeObserver!: ResizeObserver;
  currentIndex = 0;
  currentOffset = 0;

  private mounted() {
    this.resizeObserver = new ResizeObserver(() => {
      this.updateOuterContainerHeight();
      this.updateCurrentOffset();
    });

    this.resizeObserver.observe(this.getInnerContainer());
    this.updateOuterContainerHeight();
  }

  private destroyed() {
    this.resizeObserver.disconnect();
  }

  @Watch("cols")
  private colsChanged(newCols: number) {
    if (this.items.length < newCols) {
      this.currentIndex = 0;
    } else if (this.currentIndex > this.items.length - newCols) {
      this.currentIndex = this.items.length - newCols;
    }

    this.updateCurrentOffset();
  }

  updateCurrentIndex(change: number) {
    this.currentIndex += change;
    this.updateCurrentOffset();
  }

  setDotClass(index: number) {
    return index === this.currentIndex ? "dot dot-active" : "dot";
  }

  updateCurrentOffset() {
    const inner = this.getInnerContainer();
    const first = inner.children[this.currentIndex] as HTMLElement;
    assert(first != null);
    this.currentOffset = -first.offsetLeft;
  }

  private updateOuterContainerHeight() {
    const outer = this.getOuterContainer();
    const inner = this.getInnerContainer();
    outer.style.height = inner.offsetHeight + "px";
  }

  private getOuterContainer(): HTMLDivElement {
    const outer = this.$refs["outer-container"] as HTMLDivElement;
    assert(outer != null);
    return outer;
  }

  private getInnerContainer(): HTMLDivElement {
    const inner = this.$refs["inner-container"] as HTMLDivElement;
    assert(inner != null);
    return inner;
  }

  isSafariBrowser(): boolean {
    return navigator.userAgent.indexOf("Safari") != -1 && navigator.userAgent.indexOf("Chrome") == -1;
  }

  get hasLastItemSlot(): boolean {
    return !!this.$slots["last-item"];
  }

  get dotCount(): number {
    return this.items.length - this.cols + (this.hasLastItemSlot ? 2 : 1);
  }
}
