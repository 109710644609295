
import { Component, Prop, Vue } from "vue-property-decorator";
import { RestRootItem } from "@coveo/levelup-protocol";
import LoadedContent from "@/components/LoadedContent.vue";

@Component({ components: { LoadedContent } })
export default class ItemBadge extends Vue {
  @Prop() private item!: RestRootItem;
  @Prop({ default: 6 }) widthInRem!: number;
  private imageLoading = true;

  created(): void {
    const img = new Image();
    img.addEventListener("load", () => (this.imageLoading = false));
    img.src = this.item.badge;
  }

  private get rounded(): string {
    return this.widthInRem <= 2 ? "rounded" : "rounded-lg";
  }

  private get style(): Record<string, string> {
    return {
      width: `${this.widthInRem}rem`,
      height: `${this.widthInRem}rem`,
    };
  }
}
