import amplitudeAnalytics from "@/amplitude-analytics";
import { DateTime } from "luxon";
import {
  AllowedReason,
  ItemType,
  RestEvent,
  RestEventCollection,
  RestRoadmap,
  RestRootItem,
  RestSkillPath,
} from "@coveo/levelup-protocol";

export enum ReportType {
  Bundle = "Bundle",
  Item = "Item",
  User = "User",
}

export default {
  redirectToNotFound(): void {
    window.location.href = `/not-found?returnUrl=${encodeURIComponent(window.location.href)}`;
  },

  getLoginUrl: function (returnUrl: string): string {
    return `/login?returnUrl=${encodeURIComponent(returnUrl)}`;
  },

  redirectToLogin: function (): void {
    amplitudeAnalytics.logAmplitudeEvent("clicked login");
    window.location.href = this.getLoginUrl(window.location.href);
  },

  getRootItemViewUrl: function (item: RestRootItem): string {
    switch (item.type) {
      case ItemType.SkillPath:
        return this.getSkillPathViewUrl(item.slug);
      case ItemType.Roadmap:
        return this.getRoadmapViewUrl(item.slug);
      case ItemType.Course:
        return this.getCourseViewUrl(item.slug);
      case ItemType.Event:
        return this.getEventViewUrl(item.slug);
      case ItemType.EventCollection:
        return this.getEventCollectionViewUrl(item.slug);
    }
  },

  getCombinedCourseStatisticsBaseUrl: function (from?: DateTime, to?: DateTime): string {
    if (from == null || to == null) {
      const date = DateTime.utc();
      from = date.startOf("quarter");
      to = date.endOf("quarter").startOf("minute");
    }

    return `/coveo-only/combined-course-statistics?from=${from.toISO()}&to=${to.toISO()}&includeAuthoringStats=false`;
  },

  getCombinedEventStatisticsBaseUrl: function (from?: DateTime, to?: DateTime): string {
    if (from == null || to == null) {
      const date = DateTime.utc();
      from = date.startOf("quarter");
      to = date.endOf("quarter").startOf("minute");
    }

    return `/coveo-only/combined-event-statistics?from=${from.toISO()}&to=${to.toISO()}&includeAuthoringStats=true`;
  },

  getReportBaseUrl: function (type: ReportType, from?: DateTime, to?: DateTime): string {
    if (from == null || to == null) {
      const date = DateTime.utc();
      from = date.startOf("quarter");
      to = date.endOf("quarter").startOf("minute");
    }

    return `/coveo-only/${type.toLowerCase()}-reporting?from=${from.toISO()}&to=${to.toISO()}`;
  },

  getCertificationsUrl: function (): string {
    return `/learn/certifications`;
  },

  getRootItemActivitiesUrl: function (item: RestRootItem): string {
    return `/coveo-only/activities/item/${item.slug}`;
  },

  getSkillPathViewUrl: function (skillPathSlug: string): string {
    return `/learn/skill-paths/${skillPathSlug}`;
  },

  getSkillPathStatsUrl: function (skillPath: RestSkillPath, from?: DateTime, to?: DateTime): string {
    return `${this.getCombinedCourseStatisticsBaseUrl(from, to)}${
      skillPath.allowedReason === AllowedReason.CoveoOnly ? "&internalUsers=true" : ""
    }&skillPath=${skillPath.id}`;
  },

  getRoadmapViewUrl: function (roadmapSlug: string): string {
    return `/learn/roadmaps/${roadmapSlug}`;
  },

  getRoadmapStatsUrl: function (roadmap: RestRoadmap, from?: DateTime, to?: DateTime): string {
    return `${this.getCombinedCourseStatisticsBaseUrl(from, to)}${
      roadmap.allowedReason === AllowedReason.CoveoOnly ? "&internalUsers=true" : ""
    }&roadmap=${roadmap.id}`;
  },

  getCourseViewUrl: function (courseSlug: string): string {
    return `/learn/courses/${courseSlug}`;
  },

  getCourseCompletedViewUrl: function (courseSlug: string): string {
    return `/learn/courses/${courseSlug}/completed`;
  },

  getCourseStatsUrl: function (courseId: string, from?: DateTime, to?: DateTime): string {
    return `${this.getReportBaseUrl(ReportType.Item, from, to)}&courses=${courseId}`;
  },

  getLessonViewUrl: function (courseSlug: string, lessonSlug: string): string {
    return `${this.getCourseViewUrl(courseSlug)}/lessons/${lessonSlug}`;
  },

  getLessonStatsUrl: function (courseSlug: string, lessonSlug: string): string {
    return `${this.getLessonViewUrl(courseSlug, lessonSlug)}/stats`;
  },

  getQuizStatsUrl: function (courseSlug: string, lessonSlug: string): string {
    return `${this.getLessonViewUrl(courseSlug, lessonSlug)}/quiz-stats`;
  },

  getDashboardUrl: function (completedExamId?: string): string {
    return `/user/dashboard${completedExamId ? `?completedExamId=${completedExamId}` : ""}`;
  },

  getEventCollectionViewUrl: function (eventCollectionSlug: string): string {
    return `/event-collections/${eventCollectionSlug}`;
  },

  getEventCollectionStatsUrl: function (eventCollection: RestEventCollection, from?: DateTime, to?: DateTime): string {
    return `${this.getCombinedEventStatisticsBaseUrl(from, to)}&archived=false${
      eventCollection.allowedReason === AllowedReason.CoveoOnly ? "&internalUsers=true" : ""
    }&eventCollection=${eventCollection.id}`;
  },

  getEventViewUrl: function (eventSlug: string): string {
    return `/events/${eventSlug}`;
  },

  getEventStatsUrl: function (event: RestEvent, from?: DateTime, to?: DateTime): string {
    return `${this.getCombinedEventStatisticsBaseUrl(from, to)}${
      event.allowedReason === AllowedReason.CoveoOnly ? "&internalUsers=true" : ""
    }&event=${event.id}`;
  },

  getEventICalUrl: function (eventSlug: string): string {
    return `/rest/training/events/${eventSlug}/ical`;
  },

  getSessionViewUrl: function (eventSlug: string, sessionSlug: string): string {
    return `${this.getEventViewUrl(eventSlug)}/sessions/${sessionSlug}`;
  },

  getSessionStatsUrl: function (eventSlug: string, sessionSlug: string): string {
    return `${this.getSessionViewUrl(eventSlug, sessionSlug)}/stats`;
  },

  getSessionICalUrl: function (eventSlug: string, sessionSlug: string): string {
    return `/rest/training/events/${eventSlug}/sessions/${sessionSlug}/ical`;
  },

  getSessionSegmentICalUrl: function (eventSlug: string, sessionSlug: string, segment: number): string {
    return `/rest/training/events/${eventSlug}/sessions/${sessionSlug}/${segment}/ical`;
  },

  getTeamViewUrl: function (teamId: string): string {
    return `/teams/${teamId}`;
  },

  getTeamStatsUrl: function (teamId: string, from?: DateTime, to?: DateTime): string {
    return `${this.getCombinedCourseStatisticsBaseUrl(from, to)}&team=${teamId}`;
  },

  getTeamCreateUrl: function (teamId: string): string {
    return `${this.getTeamViewUrl(teamId)}/create`;
  },

  getTeamEditUrl: function (teamId: string): string {
    return `${this.getTeamViewUrl(teamId)}/edit`;
  },

  getUserViewUrl: function (userId: string): string {
    return `/coveo-only/users/${userId}`;
  },

  getAccountsUrl: function (): string {
    return `/coveo-only/accounts`;
  },

  getAccountViewUrl: function (accountId: string): string {
    return `${this.getAccountsUrl()}/${accountId}/view`;
  },

  getAccountStatsUrl: function (accountId: string, from?: DateTime, to?: DateTime): string {
    return `${this.getCombinedCourseStatisticsBaseUrl(from, to)}&internalUsers=false&account=${accountId}`;
  },

  getEmployeeGroupsUrl: function (): string {
    return "/coveo-only/employee-groups/all";
  },

  getEmployeeGroupViewUrl: function (groupId: string): string {
    return `/coveo-only/employee-groups/${groupId}`;
  },

  getEmployeeGroupStatsUrl: function (employeeGroupId: string, from?: DateTime, to?: DateTime): string {
    return `${this.getCombinedCourseStatisticsBaseUrl(from, to)}&internalUsers=true&employeeGroup=${employeeGroupId}`;
  },

  getUserAssignmentsUrl: function (): string {
    return "/coveo-only/assignments";
  },

  getAssignableBundlesUrl: function (): string {
    return "/coveo-only/assignments/assignable-bundles";
  },

  getAssignableBundleViewUrl: function (bundleSlug: string): string {
    return `/coveo-only/assignments/${bundleSlug}`;
  },

  getAssignableBundleStatsUrl: function (bundleId: string, from?: DateTime, to?: DateTime): string {
    return `${this.getReportBaseUrl(ReportType.Bundle, from, to)}&bundles=${bundleId}`;
  },

  getUserStatsUrl: function (userId: string, from?: DateTime, to?: DateTime): string {
    return `${this.getReportBaseUrl(ReportType.User, from, to)}&users=${userId}&includeInactiveUsers=true`;
  },

  getSearchUrl: function (): string {
    return "/search";
  },

  getCatalogRootItemViewUrl(itemIdOrSlug: string, itemType: ItemType) {
    switch (itemType) {
      case ItemType.Course:
        return this.getCourseViewUrl(itemIdOrSlug);
      case ItemType.Event:
        return this.getEventViewUrl(itemIdOrSlug);
      case ItemType.EventCollection:
        return this.getEventCollectionViewUrl(itemIdOrSlug);
      case ItemType.Roadmap:
        return this.getRoadmapViewUrl(itemIdOrSlug);
      case ItemType.SkillPath:
        return this.getSkillPathViewUrl(itemIdOrSlug);
    }
  },
};
