
import { Component, Prop, Vue } from "vue-property-decorator";
import { RestCompletableItem } from "@coveo/levelup-protocol";
import Started from "@/assets/started.svg";
import Completed from "@/assets/completed.svg";

@Component({
  components: {
    Started,
    Completed,
  },
})
export default class ItemCompletion extends Vue {
  @Prop({ default: false }) dark!: boolean;
  @Prop({ required: true }) item!: RestCompletableItem;
  @Prop({ default: false }) small!: boolean;

  get itemCompletionPercentage(): number {
    if (this.item.completed) {
      return 100;
    } else {
      return Math.round(this.item.progress * 100);
    }
  }

  // Always show a tiny bit of the progress bar, even at 0% progress
  get progressBarValue(): number {
    return Math.round(4 + this.itemCompletionPercentage * 0.96);
  }
}
