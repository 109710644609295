import { DateTime, Duration } from "luxon";
import { RestUser } from "@coveo/levelup-protocol";

export default {
  formatDateRange: function (from: DateTime, to: DateTime): string {
    const toString = to.toFormat("d MMM yyyy");
    if (from.hasSame(to, "day")) {
      return toString;
    }

    let fromFormat = "d";
    if (!from.hasSame(to, "month")) {
      fromFormat += " MMM";
    }
    if (!from.hasSame(to, "year")) {
      fromFormat += " yyyy";
    }
    return `${from.toFormat(fromFormat)} - ${toString}`;
  },

  formatDay: function (date: DateTime): string {
    return date.toLocaleString(DateTime.DATE_FULL);
  },

  formatTime: function (date: string): string {
    return DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE);
  },

  formatDuration: function (secondsSpent: number | null, short: boolean): string {
    const s = secondsSpent != null ? secondsSpent : 0;
    const duration = Duration.fromObject({ seconds: s }).normalize();

    const hours = duration.as("hours");
    const minutes = duration.as("minutes");
    const seconds = duration.as("seconds");

    if (hours > 2) {
      return `${Math.floor(hours)}${short ? "h" : " hours"}`;
    } else if (minutes > 2) {
      return `${Math.floor(minutes)}${short ? "m" : " minutes"}`;
    } else {
      return `${Math.floor(seconds)}${short ? "s" : " seconds"}`;
    }
  },

  formatCompany: function (user: RestUser): string {
    if (user.title != null && user.company != null) {
      return `${user.title} @ ${user.company}`;
    } else if (user.title != null) {
      return user.title;
    } else if (user.company != null) {
      return user.company;
    } else {
      return "";
    }
  },
};
