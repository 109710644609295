import axios from "axios";
import { context } from "@/globals";
import assert from "assert";
import { RestInitConfig } from "@coveo/levelup-protocol";

export class SearchAnalyticsClient {
  private endpoint: string;
  private analyticsToken: string;

  constructor(config: RestInitConfig) {
    assert(config.search != null);
    this.endpoint = `${config.search.analyticsEndpoint}/rest/ua/v15/analytics/view?org=${config.search.organizationId}`;
    this.analyticsToken = config.search.searchToken;
  }

  async logPageView(data: Record<string, unknown> = {}): Promise<void> {
    await axios({
      method: "post",
      url: this.endpoint,
      headers: {
        authorization: `Bearer ${this.analyticsToken}`,
      },
      data: {
        ...data,
        location: window.location.href,
        anonymous: context.user == null,
        contentIdKey: "@permanentid",
        language: "en",
        userAgent: window.navigator.userAgent,
        referrer: document.referrer === "" ? undefined : document.referrer,
      },
    });
  }
}
