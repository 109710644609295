
import { Component, Prop, Vue } from "vue-property-decorator";
import { calls, context } from "@/globals";
import { RestUserTeam } from "@coveo/levelup-protocol";
import TeamCard from "@/components/teams/TeamCard.vue";
import InfoBox from "@/components/InfoBox.vue";
import { EventBus } from "@/event-bus";
import amplitudeAnalytics from "@/amplitude-analytics";
import navigation from "@/navigation";
import TeamsIcon from "@/assets/teams.svg";
import EditIcon from "@/assets/edit.svg";
import LoadedContent from "@/components/LoadedContent.vue";

@Component({
  components: { InfoBox, TeamCard, TeamsIcon, EditIcon, LoadedContent },
})
export default class UserTeams extends Vue {
  @Prop({ default: false }) manage!: boolean;
  @Prop({ default: false }) sidebar!: boolean;
  context = context;
  creatingTeam = false;
  error = false;
  ready = false;
  teamsIcon: Vue = TeamsIcon;
  teams: RestUserTeam[] = [];

  private async created(): Promise<void> {
    if (!context.authenticated) {
      return;
    } else if (context.user != null) {
      try {
        this.teams = (await calls.listUserTeams(context.user.userId)).teams;
        this.ready = true;
      } catch {
        this.error = true;
      }
    }
  }

  async createTeam() {
    this.creatingTeam = true;
    const team = await calls.createTeam();
    const members = await calls.listTeamMembers(team.teamId);
    await EventBus.emitTeamsChange();
    amplitudeAnalytics.logTeamEvent("created team", team, members.members);
    void this.$router.push(navigation.getTeamCreateUrl(team.teamId));
  }
}
