
import { Component, Prop } from "vue-property-decorator";
import { VDialog } from "vuetify/lib";

@Component({})
export default class CoveoDialog extends VDialog {
  @Prop({ default: true }) private closeButton!: boolean;
  @Prop({ default: "coveo-dialog" }) private contentClass!: string;
  private x: HTMLDivElement | null = null;

  updated(): void {
    const dialog = document.querySelector(".v-dialog--active") as HTMLDivElement;

    if (this.closeButton && dialog != null && this.x == null) {
      dialog.style.position = "relative";

      const x = document.createElement("div");
      Object.assign(x.style, {
        position: "absolute",
        width: "min-content",
        right: "4px",
        top: "-36px",
        color: "white",
        fontSize: "24px",
        cursor: "pointer",
        transform: "scale(1.5, 1)",
      });
      x.innerText = "X";
      x.addEventListener("click", () => this.$emit("input", false));
      this.x = dialog.appendChild(x);
    } else if (dialog == null) {
      this.x?.remove();
      this.x = null;
    }
  }
}
