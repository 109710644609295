
import { Component, Prop, Vue } from "vue-property-decorator";
import { RestRootItem, isCourse } from "@coveo/levelup-protocol";
import navigation from "@/navigation";
import ItemBadge from "@/components/items/ItemBadge.vue";
import ItemLevel from "@/components/items/ItemLevel.vue";
import ItemTags from "@/components/items/ItemTags.vue";
import ItemPoints from "@/components/items/ItemPoints.vue";
import ItemTime from "@/components/items/ItemTime.vue";
import ItemAudiences from "@/components/items/ItemAudiences.vue";
import { localizeStringByContext } from "@coveo/levelup-protocol";
import { context } from "@/globals";

@Component({
  components: {
    ItemBadge,
    ItemLevel,
    ItemTags,
    ItemPoints,
    ItemTime,
    ItemAudiences,
  },
})
export default class MosaicTile extends Vue {
  private navigation = navigation;
  private isCourse = isCourse;
  private localizeString = localizeStringByContext;
  @Prop({ required: true }) private item!: RestRootItem;
  @Prop({ default: false }) private small!: boolean;
  @Prop({ required: true }) private assignedToUser!: boolean;
  private context = context;
}
