
import { Component, Prop, Vue } from "vue-property-decorator";
import navigation from "@/navigation";
import CoveoLogo from "@/components/navbar/CoveoLogo.vue";
import LanguageToggle from "@/components/LanguageToggle.vue";
import Ccpa from "@/assets/ccpa.svg";
import { context } from "@/globals";
import _ from "lodash";

interface Link {
  title: string;
  url: string;
}

@Component({
  components: { CoveoLogo, Ccpa, LanguageToggle },
})
export default class Footer extends Vue {
  @Prop({ default: false }) noTopMargin!: boolean;
  context = context;
  navigation = navigation;
  learnLinks: Link[] = [
    { title: "Browse courses", url: "/learn/courses" },
    { title: "Browse skill paths", url: "/learn/skill-paths" },
    { title: "Browse roadmaps", url: "/learn/roadmaps" },
  ];
  oneTrust: Record<string, unknown> | null = null;
  private allAccountLinks: Link[] = [
    { title: "Dashboard", url: "/user/dashboard" },
    { title: "Test organizations", url: "/user/testorgs" },
    { title: "Logout", url: "/logout" },
  ];

  public mounted(): void {
    if (typeof window !== "undefined") {
      this.oneTrust = window.OneTrust;
    }
  }

  get accountLinks(): Link[] {
    if (this.context.testOrgsEnabled) {
      return this.allAccountLinks;
    }
    return _.filter(this.allAccountLinks, (l) => {
      return l.url !== "/user/testorgs";
    });
  }
}
