
import { Component, Prop, Vue } from "vue-property-decorator";
import Basic from "@/assets/basic.svg";
import Intermediate from "@/assets/intermediate.svg";
import Advanced from "@/assets/advanced.svg";
import { DifficultyLevel, getDifficultyLevelCaption } from "@coveo/levelup-protocol";
import { RestCourse, RestSession } from "@coveo/levelup-protocol";
@Component({
  components: {
    Basic,
    Intermediate,
    Advanced,
  },
})
export default class ItemLevel extends Vue {
  private getDifficultyLevelCaption = getDifficultyLevelCaption;
  @Prop({ required: true }) item!: RestCourse | RestSession;
  @Prop({ default: false }) small!: boolean;

  getLevelImage(): Vue | undefined {
    switch (this.item.level) {
      case DifficultyLevel.Basic:
        return Basic;
      case DifficultyLevel.Intermediate:
        return Intermediate;
      case DifficultyLevel.Advanced:
        return Advanced;
      default:
        return undefined;
    }
  }
}
