
import { Component, Vue } from "vue-property-decorator";
import RedLens from "@/assets/Red.svg";
import TurquoiseLens from "@/assets/Turquoise.svg";
import YellowLens from "@/assets/Yellow.svg";

@Component({
  components: {
    RedLens,
    TurquoiseLens,
    YellowLens,
  },
})
export default class BackgroundLenses extends Vue {}
