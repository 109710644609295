
import { Component, Prop, Vue } from "vue-property-decorator";
import { calls } from "@/globals";
import { RestRootItem } from "@coveo/levelup-protocol";
import amplitudeAnalytics from "@/amplitude-analytics";
import Star from "@/assets/star.svg";
import { EventBus } from "@/event-bus";

@Component({
  components: {
    Star,
  },
})
export default class ItemStar extends Vue {
  @Prop({ required: true }) private item!: RestRootItem;
  private starred = false;

  private created() {
    this.starred = this.item.starred;
  }

  private async toggle() {
    this.item.starred = !this.item.starred;
    this.starred = this.item.starred;
    await calls.starOrRemoveStarFromItem(this.item.slug, this.item.starred);
    await EventBus.emitStarredItemsChange();
    this.$forceUpdate();

    if (this.item.starred) {
      amplitudeAnalytics.logRootItemEvent("starred item", this.item);
    }
  }
}
