
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class FullWidthSection extends Vue {
  @Prop({ default: false, type: Boolean }) borderTop!: boolean;
  @Prop({ default: false, type: Boolean }) gray!: boolean;
}
