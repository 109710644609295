import sleep from "sleep-promise";

declare global {
  interface Window {
    OptanonActiveGroups: string;
    OneTrust: Record<string, unknown>;
  }
}

export default {
  performanceCookiesEnabled(): boolean {
    return window.OptanonActiveGroups.includes("C0002");
  },
  async fetchOneTrustInstance(): Promise<Record<string, unknown>> {
    while (window.OneTrust == null) {
      await sleep(500);
    }
    return window.OneTrust;
  },
};
