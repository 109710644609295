
import { Component, Prop, Vue } from "vue-property-decorator";
import SleepyGhost from "@/assets/loading-screen-error.svg";

@Component({ components: { SleepyGhost } })
export default class Loading extends Vue {
  @Prop({ default: 1 }) private seconds!: number;
  @Prop({ default: false }) private error!: boolean;
  private progress = 0;
  private interval: number | undefined = undefined;

  created(): void {
    this.interval = window.setInterval(this.increaseProgress, this.seconds * 50);
  }

  private increaseProgress() {
    this.progress += 5;
    if (this.progress === 100) {
      window.clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  beforeDestroy(): void {
    if (this.interval != null) {
      window.clearInterval(this.interval);
    }
  }
}
