import {
  loadFieldActions,
  SearchBox,
  Facet,
  ResultList,
  ResultsPerPage,
  Pager,
  UrlManager,
  buildSearchBox,
  buildSearchEngine,
  buildResultList,
  buildFacet,
  buildResultsPerPage,
  buildPager,
  buildUrlManager,
  Sort,
  buildSort,
  SearchEngine,
  SortCriterion,
  buildRelevanceSortCriterion,
  buildDateSortCriterion,
  SortOrder,
  getOrganizationEndpoints,
  buildContext,
  buildDidYouMean,
  DidYouMean,
} from "@coveo/headless";
import { context } from "@/globals";
import { limits } from "@coveo/levelup-protocol";
import oneTrust from "@/one-trust";

export interface HeadlessInfo {
  engine: SearchEngine;
  searchBox: SearchBox;
  resultList: ResultList;
  facets: {
    type: Facet;
    level: Facet;
    audience: Facet;
    category: Facet;
    solution: Facet;
    integration: Facet;
    trainingScope: Facet;
    internalContentType: Facet;
  };
  resultsPerPage: ResultsPerPage;
  pager: Pager;
  urlManager: UrlManager;
  sort: {
    controller: Sort;
    criteria: SortCriterionWithLabel[];
  };
  didYouMean: DidYouMean;
}

export interface SortCriterionWithLabel {
  label: string;
  criterion: SortCriterion;
}

export let headless: HeadlessInfo | null = null;

export async function initializeHeadlessEngine(): Promise<void> {
  if (context.config.search == null) {
    return;
  }

  const engineInstance = buildSearchEngine({
    configuration: {
      organizationEndpoints: getOrganizationEndpoints(context.config.search.organizationId),
      organizationId: context.config.search.organizationId,
      accessToken: context.config.search.searchToken,
      search: {
        searchHub: context.config.search.searchHub,
      },
    },
  });

  if (!context.disableOneTrust && !oneTrust.performanceCookiesEnabled()) {
    engineInstance.disableAnalytics();
  }

  if (context.user != null) {
    const userContext = buildContext(engineInstance);
    userContext.set({
      coveoEmployee: context.user.coveoEmployee.toString(),
      externalConsultant: context.user.externalConsultant.toString(),
    });
    if (context.user.country != null) {
      userContext.add("country", context.user.country);
    }
    if (context.user.categories.length > 0) {
      userContext.add("categories", context.user.categories);
    }
    if (context.user.integrations.length > 0) {
      userContext.add("integrations", context.user.integrations);
    }
    if (context.user.personas.length > 0) {
      userContext.add("personas", context.user.personas);
    }
    if (context.user.solutions.length > 0) {
      userContext.add("solutions", context.user.solutions);
    }
  }

  const searchBox = buildSearchBox(engineInstance, {
    options: {
      highlightOptions: {
        notMatchDelimiters: {
          open: "<strong>",
          close: "</strong>",
        },
        correctionDelimiters: {
          open: "<i>",
          close: "</i>",
        },
      },
    },
  });

  const resultList = buildResultList(engineInstance);

  const facets = createFacets(engineInstance);

  const resultsPerPage = buildResultsPerPage(engineInstance, {
    initialState: { numberOfResults: limits.maximumNumberOfResults },
  });

  const pager = buildPager(engineInstance);

  const criteria: SortCriterionWithLabel[] = [
    {
      label: "Relevance",
      criterion: buildRelevanceSortCriterion(),
    },
    {
      label: "Date (Ascending)",
      criterion: buildDateSortCriterion(SortOrder.Ascending),
    },
    {
      label: "Date (Descending)",
      criterion: buildDateSortCriterion(SortOrder.Descending),
    },
  ];

  const sort = {
    controller: buildSort(engineInstance, { initialState: { criterion: criteria[0].criterion } }),
    criteria: criteria,
  };

  const urlManager = buildUrlManager(engineInstance, { initialState: { fragment: window.location.hash.slice(1) } });

  const didYouMean = buildDidYouMean(engineInstance);

  headless = {
    engine: engineInstance,
    searchBox,
    resultList,
    facets,
    resultsPerPage,
    pager,
    urlManager,
    sort,
    didYouMean,
  };

  const publicFields = [
    "lu_levels",
    "lu_audiences",
    "lu_categories",
    "lu_badge",
    "lu_points",
    "lu_slug",
    "documenttype",
    "date",
  ];
  const internalFields = ["lu_trainingscope", "lu_internalcontenttypes"];
  const fieldsToInclude = context.user?.coveoEmployee ? [...publicFields, ...internalFields] : [...publicFields];
  headless?.engine.dispatch(loadFieldActions(headless.engine).registerFieldsToInclude(fieldsToInclude));
}

function createFacets(engine: SearchEngine): {
  type: Facet;
  level: Facet;
  audience: Facet;
  category: Facet;
  solution: Facet;
  integration: Facet;
  trainingScope: Facet;
  internalContentType: Facet;
} {
  const typeFacet = buildFacet(engine, {
    options: {
      facetId: "Type",
      field: "documenttype",
    },
  });
  const levelFacet = buildFacet(engine, {
    options: {
      facetId: "Level",
      field: "lu_levels",
    },
  });
  const audienceFacet = buildFacet(engine, {
    options: {
      facetId: "Audience",
      field: "lu_audiences",
    },
  });
  const categoryFacet = buildFacet(engine, {
    options: {
      facetId: "Category",
      field: "lu_categories",
    },
  });
  const solutionFacet = buildFacet(engine, {
    options: {
      facetId: "Solution",
      field: "lu_solutions",
    },
  });
  const integrationFacet = buildFacet(engine, {
    options: {
      facetId: "Integration",
      field: "lu_integrations",
    },
  });
  const trainingScopeFacet = buildFacet(engine, {
    options: {
      facetId: "TrainingScope",
      field: "lu_trainingscope",
    },
  });
  const internalContentTypeFacet = buildFacet(engine, {
    options: {
      facetId: "InternalContentType",
      field: "lu_internalcontenttypes",
    },
  });
  return {
    type: typeFacet,
    level: levelFacet,
    audience: audienceFacet,
    category: categoryFacet,
    solution: solutionFacet,
    integration: integrationFacet,
    trainingScope: trainingScopeFacet,
    internalContentType: internalContentTypeFacet,
  };
}
