import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import navigation from "@/navigation";

// All views are lazy loaded
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// Main app
const ViewCourse = () => import("@/views/ViewCourse.vue");
const ViewLesson = () => import("@/views/ViewLesson.vue");
const Roadmaps = () => import("@/views/Roadmaps.vue");
const Courses = () => import("@/views/Courses.vue");
const InternalContent = () => import("@/views/InternalContent.vue");
const CombinedCourseStatistics = () => import("@/views/reporting/CombinedCourseStatistics.vue");
const AssignableBundleReporting = () => import("@/views/reporting/bundles/AssignableBundleReporting.vue");
const CombinedEventStatistics = () => import("@/views/reporting/CombinedEventStatistics.vue");
const ItemReporting = () => import("@/views/reporting/items/ItemReporting.vue");
const UserReporting = () => import("@/views/reporting/users/UserReporting.vue");
const QuizStats = () => import("@/views/admin/QuizStats.vue");
const Search = () => import("@/views/Search.vue");
const Dashboard = () => import("@/views/Dashboard.vue");
const Leaderboard = () => import("@/views/Leaderboard.vue");
const Teams = () => import("@/views/teams/Teams.vue");
const ViewTeam = () => import("@/views/teams/ViewTeam.vue");
const Users = () => import("@/views/users/Users.vue");
const Accounts = () => import("@/views/accounts/Accounts.vue");
const EditTeam = () => import("@/views/teams/EditTeam.vue");
const ViewUser = () => import("@/views/users/ViewUser.vue");
const ViewRoadmap = () => import("@/views/ViewRoadmap.vue");
const TeamInvitation = () => import("@/views/teams/TeamInvitation.vue");
const TestOrgs = () => import("@/views/users/TestOrgs.vue");
const ViewEventCollection = () => import("@/views/events/ViewEventCollection.vue");
const ViewEvent = () => import("@/views/events/ViewEvent.vue");
const Events = () => import("@/views/events/Events.vue");
const ViewSession = () => import("@/views/events/ViewSession.vue");
const ViewAccount = () => import("@/views/accounts/ViewAccount.vue");
const AllActivity = () => import("@/views/AllActivity.vue");
const ItemActivity = () => import("@/views/items/ItemActivity.vue");
const NotFound = () => import("@/views/NotFound.vue");
const SkillPaths = () => import("@/views/SkillPaths.vue");
const Certifications = () => import("@/views/Certifications.vue");
const ViewSkillPath = () => import("@/views/ViewSkillPath.vue");
const CourseCompleted = () => import("@/views/CourseCompleted.vue");
const EmployeeGroups = () => import("@/views/employeeGroups/EmployeeGroups.vue");
const ViewEmployeeGroup = () => import("@/views/employeeGroups/ViewEmployeeGroup.vue");
const AssignableBundles = () => import("@/views/AssignableBundles.vue");
const ViewAssignableBundle = () => import("@/views/ViewAssignableBundle.vue");
const UserAssignments = () => import("@/views/UserAssignments.vue");
const ManagePromotionalBanners = () => import("@/views/banners/ManagePromotionalBanners.vue");
const PromotionalBannersPreview = () => import("@/views/banners/PromotionalBannersPreview.vue");

// Authoring stuff
const EditCourse = () => import("@/authoring/EditCourse.vue");
const EditRoadmap = () => import("@/authoring/EditRoadmap.vue");
const EditEventCollection = () => import("@/authoring/EditEventCollection.vue");
const EditEvent = () => import("@/authoring/EditEvent.vue");
const EditSkillPath = () => import("@/authoring/EditSkillPath.vue");
const EditAssignableBundle = () => import("@/authoring/EditAssignableBundle.vue");

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */

enum RoutePermission {
  CoveoEmployee = "coveoEmployee",
  ExternalConsultant = "externalConsultant",
  ContentCreator = "contentCreator",
  Administrator = "administrator",
  EmployeeGroupAdmin = "employeeGroupAdmin",
  ReportViewer = "reportViewer",
  PromotionalBannerEditor = "promotionalBannerEditor",
}

Vue.use(VueRouter);

export const generalBreadcrumb = {
  rootLabel: "Home",
  rootUrl: "/",
};

export const eventCollectionsBreadcrumb = {
  rootLabel: "Event Collections",
  rootUrl: "/event-collections",
};

export const eventsBreadcrumb = {
  rootLabel: "Events",
  rootUrl: "/events",
};

export const searchBreadcrumb = {
  rootLabel: "Search",
  rootUrl: "/search",
};

export const teamsBreadcrumb = {
  rootLabel: "Teams",
  rootUrl: "/teams",
};

export const userBreadcrumb = {
  rootLabel: "User",
  rootUrl: "/user",
};

export const coveoOnlyBreadcrumb = {
  rootLabel: "Coveo Only",
  rootUrl: "/coveo-only",
};

export const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/welcome",
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: Home,
    meta: {
      breadcrumb: generalBreadcrumb,
    },
    children: [
      {
        path: "recommendations",
        meta: {
          breadcrumb: generalBreadcrumb,
        },
      },
      {
        path: "assigned-to-me",
        meta: {
          breadcrumb: generalBreadcrumb,
        },
      },
      {
        path: "starred",
        meta: {
          breadcrumb: generalBreadcrumb,
        },
      },
      {
        path: "courses",
        meta: {
          breadcrumb: generalBreadcrumb,
        },
      },
      {
        path: "roadmaps",
        meta: {
          breadcrumb: generalBreadcrumb,
        },
      },
      {
        path: "skill-paths",
        meta: {
          breadcrumb: generalBreadcrumb,
        },
      },
    ],
  },
  {
    path: "/learn",
    redirect: "/learn/courses",
  },
  {
    path: "/learn/certifications",
    name: "All certifications",
    component: Certifications,
    meta: {
      breadcrumb: generalBreadcrumb,
    },
  },
  {
    path: "/learn/skill-paths",
    name: "All skill paths",
    component: SkillPaths,
    meta: {
      breadcrumb: generalBreadcrumb,
    },
  },
  {
    path: "/learn/skill-paths/:skillPathSlug",
    name: "Skill path",
    component: ViewSkillPath,
    meta: {
      breadcrumb: generalBreadcrumb,
    },
  },
  {
    path: "/learn/roadmaps",
    name: "All roadmaps",
    component: Roadmaps,
    meta: {
      breadcrumb: generalBreadcrumb,
    },
  },
  {
    path: "/learn/roadmaps/:roadmapSlug",
    name: "Roadmap",
    component: ViewRoadmap,
    meta: {
      breadcrumb: generalBreadcrumb,
    },
  },
  {
    path: "/learn/courses",
    name: "All courses",
    component: Courses,
    meta: {
      breadcrumb: generalBreadcrumb,
    },
  },
  {
    path: "/coveo-only",
    redirect: navigation.getUserAssignmentsUrl(),
  },
  {
    path: "/coveo-only/combined-course-statistics",
    name: "Combined course statistics",
    component: CombinedCourseStatistics,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [RoutePermission.ReportViewer],
    },
  },
  {
    path: "/coveo-only/bundle-reporting",
    name: "Bundle reporting",
    component: AssignableBundleReporting,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [RoutePermission.ReportViewer],
    },
  },
  {
    path: "/coveo-only/combined-event-statistics",
    name: "Combined event statistics",
    component: CombinedEventStatistics,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [RoutePermission.ReportViewer],
    },
  },
  {
    path: "/coveo-only/item-reporting",
    name: "Item reporting",
    component: ItemReporting,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [RoutePermission.ReportViewer],
    },
  },
  {
    path: "/coveo-only/user-reporting",
    name: "User reporting",
    component: UserReporting,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [RoutePermission.ReportViewer],
    },
  },
  {
    path: "/learn/courses/:courseSlug",
    name: "Course",
    component: ViewCourse,
    meta: {
      breadcrumb: generalBreadcrumb,
    },
  },
  {
    path: "/learn/courses/:courseSlug/lessons/:lessonSlug",
    name: "Lesson",
    component: ViewLesson,
    meta: {
      breadcrumb: generalBreadcrumb,
    },
  },
  {
    path: "/learn/courses/:courseSlug/completed",
    name: "Course Completed",
    component: CourseCompleted,
    meta: {
      breadcrumb: generalBreadcrumb,
    },
  },
  {
    path: "/learn/internal",
    name: "All internal content",
    component: InternalContent,
    meta: {
      breadcrumb: generalBreadcrumb,
      routePermissions: [RoutePermission.CoveoEmployee, RoutePermission.ExternalConsultant],
    },
  },
  {
    path: "/event-collections/:eventCollectionSlug",
    name: "Event collection",
    component: ViewEventCollection,
    meta: {
      breadcrumb: eventsBreadcrumb,
    },
  },
  {
    path: "/events",
    name: "All events",
    component: Events,
    meta: {
      breadcrumb: eventsBreadcrumb,
      noFooterTopMargin: true,
    },
  },
  {
    path: "/events/:eventSlug",
    name: "Event",
    component: ViewEvent,
    meta: {
      breadcrumb: eventsBreadcrumb,
    },
    children: [
      {
        path: "overview",
        meta: {
          breadcrumb: eventsBreadcrumb,
        },
      },
      {
        path: "agenda",
        meta: {
          breadcrumb: eventsBreadcrumb,
        },
      },
      {
        path: "prerequisites",
        meta: {
          breadcrumb: eventsBreadcrumb,
        },
      },
      {
        path: "event-items",
        meta: {
          breadcrumb: eventsBreadcrumb,
        },
      },
    ],
  },
  {
    path: "/events/:eventSlug/sessions/:sessionSlug",
    name: "Watch session",
    component: ViewSession,
    meta: {
      breadcrumb: eventsBreadcrumb,
      naked: true,
    },
  },
  {
    path: "/search",
    name: "Results",
    component: Search,
    meta: {
      breadcrumb: searchBreadcrumb,
    },
  },
  {
    path: "/leaderboard",
    name: "Leaderboard",
    component: Leaderboard,
    meta: {
      breadcrumb: generalBreadcrumb,
    },
  },
  {
    path: "/teams",
    redirect: "/teams/manage-teams",
  },
  {
    path: "/teams/manage-teams",
    name: "Manage teams",
    component: Teams,
    meta: {
      breadcrumb: teamsBreadcrumb,
    },
  },
  {
    path: "/teams/:teamId",
    name: "Team",
    component: ViewTeam,
    meta: {
      breadcrumb: teamsBreadcrumb,
    },
  },
  {
    path: "/teams/:teamId/edit",
    name: "Edit team",
    component: EditTeam,
    meta: {
      breadcrumb: teamsBreadcrumb,
    },
  },
  {
    path: "/teams/:teamId/create",
    name: "Create team",
    component: EditTeam,
    props: {
      newTeam: true,
    },
    meta: {
      breadcrumb: teamsBreadcrumb,
    },
  },
  {
    path: "/teams/:teamId/invitation",
    name: "Review team invitation",
    component: TeamInvitation,
    meta: {
      breadcrumb: teamsBreadcrumb,
    },
  },
  {
    path: "/user",
    redirect: "/user/dashboard",
  },
  {
    path: "/user/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      breadcrumb: userBreadcrumb,
      noFooterTopMargin: true,
    },
  },
  {
    path: "/user/testorgs",
    name: "Test Organizations",
    component: TestOrgs,
    meta: {
      breadcrumb: userBreadcrumb,
    },
  },
  {
    path: "/learn/courses/:courseSlug/lessons/:lessonSlug/quiz-stats",
    name: "Quiz stats",
    component: QuizStats,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ReportViewer],
    },
  },
  {
    path: "/coveo-only/activities/all",
    name: "View all activity",
    component: AllActivity,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ReportViewer],
    },
  },
  {
    path: "/coveo-only/activities/item/:slug",
    name: "View item activity",
    component: ItemActivity,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ReportViewer],
    },
  },
  {
    path: "/coveo-only/users",
    name: "Users",
    component: Users,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ReportViewer],
    },
  },
  {
    path: "/coveo-only/users/:userId",
    name: "View user",
    component: ViewUser,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ReportViewer],
    },
  },
  {
    path: navigation.getAccountsUrl(),
    name: "View Accounts",
    component: Accounts,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ReportViewer],
    },
  },
  {
    path: navigation.getAccountViewUrl(":accountId"),
    name: "View account",
    component: ViewAccount,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ReportViewer],
    },
  },
  {
    path: navigation.getEmployeeGroupsUrl(),
    name: "Manage employee groups",
    component: EmployeeGroups,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [
        RoutePermission.EmployeeGroupAdmin,
        RoutePermission.ReportViewer,
        RoutePermission.Administrator,
      ],
    },
  },
  {
    path: navigation.getEmployeeGroupViewUrl(":groupId"),
    name: "View employee group",
    component: ViewEmployeeGroup,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [
        RoutePermission.EmployeeGroupAdmin,
        RoutePermission.ReportViewer,
        RoutePermission.Administrator,
      ],
    },
  },
  {
    path: navigation.getAssignableBundlesUrl(),
    name: "All assignable bundles",
    component: AssignableBundles,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [
        RoutePermission.EmployeeGroupAdmin,
        RoutePermission.ContentCreator,
        RoutePermission.Administrator,
      ],
    },
  },
  {
    path: navigation.getAssignableBundleViewUrl(":bundleSlug"),
    name: "View assignable bundle",
    component: ViewAssignableBundle,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [
        RoutePermission.CoveoEmployee,
        RoutePermission.ExternalConsultant,
        RoutePermission.Administrator,
      ],
    },
  },
  {
    path: navigation.getUserAssignmentsUrl(),
    name: "Your assignments",
    component: UserAssignments,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [
        RoutePermission.Administrator,
        RoutePermission.CoveoEmployee,
        RoutePermission.ExternalConsultant,
      ],
    },
  },
  {
    path: "/learn/skill-paths/:skillPathSlug/new",
    name: "Create skill path",
    component: EditSkillPath,
    props: {
      isNew: true,
    },
    meta: {
      breadcrumb: generalBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ContentCreator],
    },
  },
  {
    path: "/learn/skill-paths/:skillPathSlug/edit",
    name: "Edit skill path",
    component: EditSkillPath,
    meta: {
      breadcrumb: generalBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ContentCreator],
    },
  },
  {
    path: "/learn/roadmaps/:roadmapSlug/new",
    name: "Create roadmap",
    component: EditRoadmap,
    props: {
      isNew: true,
    },
    meta: {
      breadcrumb: generalBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ContentCreator],
    },
  },
  {
    path: "/learn/roadmaps/:roadmapSlug/edit",
    name: "Edit roadmap",
    component: EditRoadmap,
    meta: {
      breadcrumb: generalBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ContentCreator],
    },
  },
  {
    path: "/learn/courses/:courseSlug/new",
    name: "Create course",
    component: EditCourse,
    props: {
      isNew: true,
    },
    meta: {
      breadcrumb: generalBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ContentCreator],
    },
  },
  {
    path: "/learn/courses/:courseSlug/edit",
    name: "Edit course",
    component: EditCourse,
    meta: {
      breadcrumb: generalBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ContentCreator],
    },
  },
  {
    path: "/event-collections/:eventCollectionSlug/new",
    name: "Create event collection",
    component: EditEventCollection,
    props: {
      isNew: true,
    },
    meta: {
      breadcrumb: generalBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ContentCreator],
    },
  },
  {
    path: "/event-collections/:eventCollectionSlug/edit",
    name: "Edit event collection",
    component: EditEventCollection,
    meta: {
      breadcrumb: generalBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ContentCreator],
    },
  },
  {
    path: "/events/:eventSlug/new",
    name: "Create event",
    component: EditEvent,
    props: {
      isNew: true,
    },
    meta: {
      breadcrumb: generalBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ContentCreator],
    },
  },
  {
    path: "/events/:eventSlug/edit",
    name: "Edit event",
    component: EditEvent,
    meta: {
      breadcrumb: generalBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ContentCreator],
    },
  },
  {
    path: "/coveo-only/assignments/assignable-bundles/:bundleSlug/new",
    name: "Create assignable bundle",
    component: EditAssignableBundle,
    meta: {
      breadcrumb: generalBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ContentCreator],
    },
    props: {
      isNew: true,
    },
  },
  {
    path: "/coveo-only/assignments/assignable-bundles/:bundleSlug/edit",
    name: "Edit assignable bundle",
    component: EditAssignableBundle,
    meta: {
      breadcrumb: generalBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.ContentCreator],
    },
  },
  {
    path: "/coveo-only/promotional-banners",
    name: "Promotional banners",
    component: ManagePromotionalBanners,
    meta: {
      breadcrumb: coveoOnlyBreadcrumb,
      routePermissions: [RoutePermission.Administrator, RoutePermission.PromotionalBannerEditor],
    },
  },
  {
    path: "/coveo-only/promotional-banners/preview",
    name: "Promotional banner preview",
    component: PromotionalBannersPreview,
    meta: {
      naked: true,
      routePermissions: [RoutePermission.Administrator, RoutePermission.PromotionalBannerEditor],
    },
  },
  {
    path: "*",
    name: "Page not found",
    component: NotFound,
    meta: {
      breadcrumb: generalBreadcrumb,
    },
  },
];
