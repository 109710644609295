
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Confirm extends Vue {
  @Prop({ required: true }) icon!: string;
  @Prop({ required: true }) color!: string;
  @Prop({ required: true }) message!: string;
  private visible = true;

  private confirm() {
    this.visible = false;
    this.$emit("confirm");
  }

  private cancel() {
    this.visible = false;
    this.$emit("cancel");
  }
}
