
import { Component, Vue, Prop } from "vue-property-decorator";
import { RestRootItem } from "@coveo/levelup-protocol";
import MosaicTile from "@/components/items/MosaicTile.vue";
import { RestAssignment, userIsAssigned } from "@coveo/levelup-protocol";
import ItemPin from "@/components/items/ItemPin.vue";

@Component({
  components: {
    MosaicTile,
    ItemPin,
  },
})
export default class ItemMosaic extends Vue {
  @Prop({ required: true }) items!: RestRootItem[];
  @Prop({ required: true }) private assignments!: RestAssignment[];
  private userIsAssigned = userIsAssigned;
}
