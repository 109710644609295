
import { Component, Prop, Vue } from "vue-property-decorator";
import Ribbon from "@/assets/ribbon.svg";
import { RestRootItem } from "@coveo/levelup-protocol";

@Component({
  components: {
    Ribbon,
  },
})
export default class ItemPoints extends Vue {
  @Prop({ required: true }) item!: RestRootItem;
  @Prop({ default: false }) small!: boolean;
}
