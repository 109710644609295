import { render, staticRenderFns } from "./UserAchievements.vue?vue&type=template&id=710243a3&scoped=true"
import script from "./UserAchievements.vue?vue&type=script&lang=ts"
export * from "./UserAchievements.vue?vue&type=script&lang=ts"
import style0 from "./UserAchievements.vue?vue&type=style&index=0&id=710243a3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_webpack@5.94.0_@swc+core@1.3.82_@swc+helpers@0.5.1____lod_5vt3kl4xqyzvtjafjcs5vhq3x4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "710243a3",
  null
  
)

export default component.exports