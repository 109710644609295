import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const neoPurple = "#270054";

const neutral1Shade0 = "#F7F7F9";
const neutral1Shade1 = "#EBEBF7";
const neutral1Shade2 = "#D3D3EA";
const neutral1Shade3 = "#AAAACC";
const neutral1Shade4 = "#7E7EA8";
const neutral1Shade5 = "#6A6A99";
const neutral1Shade6 = "#52527F";
const neutral1Shade7 = "#3F3F71";

const digitalBlue = "#1372EC";
const digitalBlue40 = "#399FFE";
const accentRed = "#F05245";
const accentBlue = "#00ADFF";
const accentYellow = "#FFE300";
const accentGreen = "#1CEBCF";

const accentRed00 = "#FFF2F1";
const accentRed20 = "#FFCDBD";
const accentRed40 = "#EE8279";
const accentGreen20 = "#B2E3DB";
const accentGreen60 = "#12C7AE";
const accentYellow20 = "#FFF7BA";
const accentYellow60 = "#EAC10B";
const accentBlue20 = "#C3ECFF";
const accentBlue60 = "#029DE7";
const accentOrange20 = "#FFCDBD";
const accentOrange40 = "#FE926E";
const accentNavyBlue00 = "#F1F2FF";
const accentNavyBlue20 = "#D6D7FD";
const accentNavyBlue40 = "#8787DB";

const black = "#282829";
const gray = "#E5E8E8";
const gray20 = "#F6F7F9";
const gray50 = "#C5CACF";
const gray60 = "#8E959D";
const gray80 = "#626971";
const white = "#FFFFFF";

const secondaryGrapePurple = "#451C5C";
const secondaryGrapePurple50 = "#752E9C";
const secondaryRainForestGreen = "#00634F";
const secondaryRainForestGreen60 = "#12C7AE";

const secondaryGovernorBlue = "#2E45BA";
const secondarySiennaOrange = "#ED7047";

const utilityInformation = "#1598EA";
const utilityInformation20 = "#CCEFFF";
const utilitySuccess = "#12A344";
const utilitySuccess20 = "#CFF7E5";
const utilitySuccess100 = "#004A20";
const utilityWarning = "#F0A702";
const utilityWarning20 = "#FFEFCC";
const utilityWarning40 = "#FDCD66";
const utilityError = "#FF3E2E";
const utilityError20 = "#FDDDDA";

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: false, // We use SASS variables for variations
      variations: false,
    },
    themes: {
      light: {
        // Those are the standard attributes from Vuetify
        primary: digitalBlue,
        secondary: secondaryGovernorBlue,
        accent: accentBlue,
        error: utilityError,
        info: utilityInformation,
        success: utilitySuccess,
        warning: utilityWarning,

        // Those are our custom colors
        black: black,
        gray: gray,
        gray20: gray20,
        gray50: gray50,
        gray60: gray60,
        gray80: gray80,
        white: white,
        neutral1Shade0,
        neutral1Shade1,
        neutral1Shade2,
        neutral1Shade3,
        neutral1Shade4,
        neutral1Shade5,
        neutral1Shade6,
        neutral1Shade7,
        neoPurple: neoPurple,
        digitalBlue: digitalBlue,
        digitalBlue40: digitalBlue40,
        accentRed: accentRed,
        accentBlue: accentBlue,
        accentYellow: accentYellow,
        accentGreen: accentGreen,
        secondaryGrapePurple: secondaryGrapePurple,
        secondaryGrapePurple50: secondaryGrapePurple50,
        secondaryRainForestGreen: secondaryRainForestGreen,
        secondaryRainForestGreen60: secondaryRainForestGreen60,
        secondaryGovernorBlue: secondaryGovernorBlue,
        secondarySiennaOrange: secondarySiennaOrange,

        // UX Color Palette
        accentRed00: accentRed00,
        accentRed20: accentRed20,
        accentRed40: accentRed40,
        accentGreen20: accentGreen20,
        accentGreen60: accentGreen60,
        accentYellow20: accentYellow20,
        accentYellow60: accentYellow60,
        accentBlue20: accentBlue20,
        accentBlue60: accentBlue60,
        accentOrange20: accentOrange20,
        accentOrange40: accentOrange40,
        accentNavyBlue00: accentNavyBlue00,
        accentNavyBlue20: accentNavyBlue20,
        accentNavyBlue40: accentNavyBlue40,
        success20: utilitySuccess20,
        success100: utilitySuccess100,
        warning20: utilityWarning20,
        warning40: utilityWarning40,
        info20: utilityInformation20,
        error20: utilityError20,
      },
      dark: {
        // Those are the standard attributes from Vuetify
        primary: digitalBlue,
        secondary: secondaryGovernorBlue,
        accent: accentBlue,
        error: utilityError,
        info: utilityInformation,
        success: utilitySuccess,
        warning: utilityWarning,
      },
    },
  },
});
