
import { Component, Vue, Prop } from "vue-property-decorator";
import InfoBox from "@/components/InfoBox.vue";
import { RestBadge, RestUserDetails } from "@coveo/levelup-protocol";
import { context, calls } from "@/globals";
import CarouselOfBadges from "@/components/users/CarouselOfBadges.vue";
import UserBadge from "@/components/users/UserBadge.vue";
import format from "@/format";
import AchievementsIcon from "@/assets/achievements.svg";
import LoadedContent from "@/components/LoadedContent.vue";

@Component({
  components: { InfoBox, CarouselOfBadges, UserBadge, AchievementsIcon, LoadedContent },
})
export default class UserAchievements extends Vue {
  @Prop({ required: true }) size!: "small" | "medium";
  @Prop({ default: false }) sidebar!: boolean;
  @Prop({ default: 3 }) carouselColumns!: number;
  private achievementsIcon: Vue = AchievementsIcon;
  private format = format;
  private context = context;
  private user!: RestUserDetails;
  private badges!: RestBadge[];
  private ready = false;
  private error = false;

  async created(): Promise<void> {
    if (!context.authenticated) {
      return;
    }

    try {
      [this.user, this.badges] = await Promise.all([calls.getCurrentUser(), (await calls.listUserBadges()).badges]);
      this.ready = true;
    } catch {
      this.error = true;
    }
  }

  private get hasAchievements(): boolean {
    return this.user.points + this.user.badges + this.user.secondsSpent > 0;
  }

  private get skeletonLoaderType(): string {
    return this.size === "small" ? "sentences, badge-carousel-3" : "text, badge-carousel-4";
  }
}
