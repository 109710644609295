
import { Component, Prop, Vue } from "vue-property-decorator";
import { RestRootItem } from "@coveo/levelup-protocol";
import LargeItemCard from "@/components/items/LargeItemCard.vue";
import SmallItemCard from "@/components/items/SmallItemCard.vue";
import Carousel from "@/components/Carousel.vue";
import { RestAssignment, userIsAssigned } from "@coveo/levelup-protocol";

@Component({
  components: { Carousel, LargeItemCard, SmallItemCard },
})
export default class CarouselOfItemCards extends Vue {
  @Prop({ required: true }) items!: RestRootItem[];
  @Prop({ default: "large" }) size!: "small" | "large";
  @Prop({ default: 2 }) cols!: number;
  @Prop({ required: true }) assignments!: RestAssignment[];
  userIsAssigned = userIsAssigned;

  get hasLastItemSlot(): boolean {
    return !!this.$slots["last-item"];
  }
}
