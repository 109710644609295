
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import UserBadge from "@/components/users/UserBadge.vue";
import { RestBadge } from "@coveo/levelup-protocol";
import Carousel from "@/components/Carousel.vue";
import _ from "lodash";

@Component({
  components: { Carousel, UserBadge },
})
export default class CarouselOfBadges extends Vue {
  @Prop({ required: true }) cols!: number;
  @Prop({ required: true }) badges!: RestBadge[];
  private badgeColumns!: RestBadge[][];
  private gap = 26;

  created(): void {
    this.setUpBadgeColumns();
  }

  @Watch("badges")
  @Watch("cols")
  private setUpBadgeColumns(): void {
    // Goal: fill left to right, top to bottom until there's no space, then fill new columns top to bottom
    if (this.badges.length >= 2 * this.cols) {
      // e.g., 5 badges, 2 cols => [[b1,b2],[b3,b4],[b5]]
      this.badgeColumns = _.chunk(this.badges, 2);
    } else if (this.badges.length <= this.cols) {
      // e.g., 3 badges, 4 cols => [[b1],[b2],[b3]]
      this.badgeColumns = this.badges.map((b) => [b]);
    } else {
      // e.g., 6 badges, 4 cols => [[b1,b5],[b2,b6],[b3],[b4]]
      const slides = this.badges.slice(0, this.cols).map((b) => [b]);
      for (let i = this.cols; i < this.badges.length; ++i) {
        slides[i - this.cols].push(this.badges[i]);
      }
      this.badgeColumns = slides;
    }
  }
}
