import { routes } from "@/router";
import Vue from "vue";
import MainApp from "./MainApp.vue";
import { initializeApplication } from "@/globals";
import VueRouter from "vue-router";
import vuetify from "../plugins/vuetify";
import { context } from "@/globals";
import { RestUserDetails } from "@coveo/levelup-protocol";
import amplitudeAnalytics from "@/amplitude-analytics";

type RestUserDetailsKey = keyof typeof context.user;

function hasRoutePermissions(routePermissions: RestUserDetailsKey[], user: RestUserDetails): boolean {
  return routePermissions.some((p: RestUserDetailsKey) => user[p]);
}

initializeApplication({ disableOneTrust: false }).then(() => {
  const router = new VueRouter({
    routes,
    mode: "history",
    scrollBehavior(from) {
      if (!/^\/(search|coveo-only\/reporting)$/.test(from.path)) {
        return { x: 0, y: 0 };
      }
    },
  });

  router.beforeEach((to, from, next) => {
    if (to.meta?.routePermissions == null || context.user?.administrator) {
      next();
    } else if (context.user != null && hasRoutePermissions(to.meta.routePermissions, context.user)) {
      next();
    } else {
      next(from.path);
    }
  });

  router.afterEach((to, from) => {
    if (
      to.matched[0].path in amplitudeAnalytics.viewEventsMap &&
      (from.matched.length == 0 || to.matched[0].path !== from.matched[0].path)
    ) {
      amplitudeAnalytics.logAmplitudeEvent(amplitudeAnalytics.viewEventsMap[to.matched[0].path]);
    }
  });

  new Vue({
    router,
    vuetify,
    render: (h) => h(MainApp),
  }).$mount("#app");
});
