
import { Component, Prop, Vue } from "vue-property-decorator";
import Ghost from "@/assets/loaded-content-error.svg";

@Component({ components: { Ghost } })
export default class LoadedContent extends Vue {
  @Prop({ required: true }) private loading!: boolean;
  @Prop({ default: false }) private error!: boolean;
  @Prop({ default: "paragraph" }) private skeletonType!: string;
  @Prop({ default: "white" }) private skeletonBackground!: "white" | "gray";

  private customTypes = {
    badge: "",
    "badge-carousel-3": "badge@6",
    "badge-carousel-4": "badge@8",
    "avatar-group": "avatar@3",
    "large-image": "image",
  };
}
