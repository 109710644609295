
import { Component, Prop, Vue } from "vue-property-decorator";
import Clock from "@/assets/clock.svg";
import { RestRootItem } from "@coveo/levelup-protocol";

@Component({
  components: {
    Clock,
  },
})
export default class ItemTime extends Vue {
  @Prop({ required: true }) item!: RestRootItem;
  @Prop({ default: false }) small!: boolean;
}
