
import { Component, Prop, Vue } from "vue-property-decorator";
import { RestUserTeam } from "@coveo/levelup-protocol";
import EditIcon from "@/assets/edit.svg";
import Avatars from "./Avatars.vue";
import navigation from "@/navigation";

@Component({
  components: {
    EditIcon,
    Avatars,
  },
})
export default class TeamCard extends Vue {
  @Prop({ default: false }) condensed!: boolean;
  @Prop({ required: true }) team!: RestUserTeam;
  navigation = navigation;
}
