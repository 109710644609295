
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ErrorModal from "@/components/ErrorModal.vue";
import { context } from "@/globals";
import CoveoLogo from "@/components/navbar/CoveoLogo.vue";
import StandaloneSearchBox from "@/components/search/StandaloneSearchBox.vue";
import CoveoLinks from "@/components/navbar/CoveoLinks.vue";
import DesktopHeader from "@/components/navbar/DesktopHeader.vue";
import MobileHeader from "@/components/navbar/MobileHeader.vue";
import { Route } from "vue-router";
import Footer from "./../views/Footer.vue";
import navigation from "@/navigation";
import { EventWatcher } from "@/event-bus";
import { Language } from "@coveo/levelup-protocol";

const AuthoringBar = () => import("@/authoring/AuthoringBar.vue");
const PromotionalBanner = () => import("@/components/navbar/PromotionalBanner.vue");

@Component({
  components: {
    AuthoringBar,
    PromotionalBanner,
    MobileHeader,
    DesktopHeader,
    CoveoLinks,
    StandaloneSearchBox,
    CoveoLogo,
    ErrorModal,
    Footer,
  },
})
export default class MainApp extends Vue {
  private context = context;
  private navigation = navigation;
  private naked = false;
  private noFooterTopMargin = false;
  private watcher = new EventWatcher();

  created(): void {
    if (
      context.user?.language === Language.French ||
      (context.user == null && localStorage.getItem("language") === Language.French)
    ) {
      const chouquetteScript = document.createElement("script");
      chouquetteScript.src = "https://boulangerie.coveo.com/v1/chouquette.js";
      document.body.appendChild(chouquetteScript);
    }
    this.watcher.watchLanguage(this.forceRerender);
    this.watcher.watchPromotionalBanner(this.forceRerender);

    Vue.directive("title", {
      inserted(el, binding) {
        document.title = `${binding.value} | Coveo Level Up`;
      },
      update(el, binding) {
        if (binding.oldValue !== binding.value) {
          document.title = `${binding.value} | Coveo Level Up`;
        }
      },
    });

    this.onRouteChange(this.$router.currentRoute);
  }

  setBannerStyle(): void {
    if (!this.naked) {
      this.$vuetify.breakpoint.lgAndUp ? "banner-desktop" : "banner-mobile";
    }
  }

  beforeDestroy(): void {
    this.watcher.destroy();
  }

  private forceRerender(): void {
    location.reload();
  }

  @Watch("$route")
  private onRouteChange(route: Route): void {
    this.naked = !!route.meta?.naked;
    this.noFooterTopMargin = !!route.meta?.noFooterTopMargin;
  }
}
