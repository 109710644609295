
import { Component, Prop, Vue } from "vue-property-decorator";
import { RestRootItem } from "@coveo/levelup-protocol";
import { isCourse } from "@coveo/levelup-protocol";
import { localizeStringByContext } from "@coveo/levelup-protocol";
import ItemLevel from "@/components/items/ItemLevel.vue";
import ItemPoints from "@/components/items/ItemPoints.vue";
import ItemTime from "@/components/items/ItemTime.vue";
import ItemAudiences from "@/components/items/ItemAudiences.vue";
import ItemTags from "@/components/items/ItemTags.vue";
import ItemBadge from "@/components/items/ItemBadge.vue";
import { context } from "@/globals";
import navigation from "@/navigation";

@Component({
  components: {
    ItemLevel,
    ItemPoints,
    ItemTime,
    ItemAudiences,
    ItemTags,
    ItemBadge,
  },
})
export default class MinifiedItemCard extends Vue {
  @Prop({ default: false }) private disabled!: boolean;
  @Prop({ default: false }) clickEnabled!: boolean;
  @Prop({ default: "neutral1Shade7" }) color!: string;
  @Prop({ default: false }) completed!: boolean;
  @Prop({ default: false }) hideBadge!: boolean;
  @Prop({ required: true }) item!: RestRootItem;
  @Prop({ default: false }) selected!: boolean;
  context = context;
  isCourse = isCourse;
  localizeString = localizeStringByContext;

  handleClick() {
    if (!this.disabled) {
      this.$emit("click", this.selected);
    }
  }

  get viewUrl(): string {
    return navigation.getRootItemViewUrl(this.item as RestRootItem);
  }

  get titleSizeClass(): string {
    return this.$vuetify.breakpoint.mdAndUp ? "text-h4" : "text-h5";
  }
}
