
import { calls } from "@/globals";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ItemType, RestCourse, RestRoadmap, RestSkillPath } from "@coveo/levelup-protocol";
import { DateTime } from "luxon";

@Component
export default class ItemCardFlag extends Vue {
  @Prop({ required: true }) private item!: RestCourse | RestSkillPath | RestRoadmap;
  @Prop({ default: false }) large!: boolean;
  showUpdateAvailableTag = false;
  showNewTag = false;

  async created(): Promise<void> {
    if (
      this.item.type === "Course" &&
      this.item.previousVersionId != null &&
      !(this.item.started || this.item.completed)
    ) {
      await this.resolveShowUpdateAvailableTag(this.item.previousVersionId);
    } else if (!(this.item.started || this.item.completed)) {
      this.showNewTag = DateTime.fromISO(this.item.dateAdded) >= DateTime.now().minus({ weeks: 2 });
    }
  }

  get updateText(): string {
    if (this.showUpdateAvailableTag) {
      return "Updated";
    } else if (this.showUpdateSoonTag) {
      return "Updating soon";
    } else if (this.showNewTag) {
      return "New";
    }
    return "";
  }

  private async resolveShowUpdateAvailableTag(courseId: string): Promise<void> {
    const previousVersion = await calls.getCourse(courseId);
    const previousVersionStartedOrCompleted = previousVersion.started || previousVersion.completed;

    if (previousVersion.previousVersionId == null) return;

    if (this.item.type === ItemType.Course) {
      this.showUpdateAvailableTag = previousVersionStartedOrCompleted && !this.item.dismissedUpdateNotification;
      return;
    }

    return await this.resolveShowUpdateAvailableTag(previousVersion.previousVersionId);
  }

  get showUpdateSoonTag(): boolean {
    if (this.item.type !== ItemType.Course) {
      return false;
    }
    return !this.showUpdateAvailableTag && !!this.item.upcomingUpdate;
  }
}
