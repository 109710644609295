import { context } from "@/globals";
import {
  getChildItem,
  getCompletableItem,
  getParentItem,
  getRegisterableItem,
  ItemType,
  RestCourse,
  RestEvent,
  RestEventCollection,
  RestLesson,
  RestRoadmap,
  RestRootItem,
  RestSession,
  RestSkillPath,
} from "@coveo/levelup-protocol";
import { RestTeam, RestTeamMember } from "@coveo/levelup-protocol";
import md5 from "md5";
import { calls } from "@/globals";
import { RestAccount } from "@coveo/levelup-protocol";

const viewEventsMap: { [key: string]: string } = {
  "/welcome": "viewed home page",
  "/learn/getting-started": "viewed learn getting started",
  "/learn/courses": "viewed courses",
  "/learn/skill-paths": "viewed skill paths",
  "/learn/roadmaps": "viewed roadmaps",
  "/learn/internal": "viewed internal content",
  "/events": "viewed events",
  "/teams/manage-teams": "viewed teams",
  "/coveo-only/assignments": "viewed assignments",
  "/user/dashboard": "viewed user dashboard",
  "/user/testorgs": "viewed test organizations",
  "/leaderboard": "viewed leaderboard",
  "/coveo-only/employee-groups/all": "view employee groups",
  "/coveo-only/assignments/assignable-bundles": "viewed assignable bundles",
  "/coveo-only/users": "viewed users",
  "/coveo-only/accounts": "viewed accounts",
  "/coveo-only/activities/all": "viewed activities",
};

// This function is async because we must wait for it to complete before sending a subsequent event
async function logIdentify(): Promise<void> {
  if (context.amplitude != null) {
    const identify = new context.amplitude.Identify();
    if (context.user != null) {
      let account: RestAccount | null = null;
      if (context.user.accountId != null) {
        account = await calls.getAccount(context.user.accountId);
      }
      identify
        .set("userId", context.user.coveoEmployee ? context.user.email : md5(context.user.email))
        .set("personas", context.user.personas)
        .set("hashedCompany", context.user.company != null ? md5(context.user.company) : "")
        .set("personas", context.user.personas)
        .set("solutions", context.user.solutions)
        .set("integrations", context.user.integrations)
        .set("badges", context.user.badges)
        .set("points", context.user.points)
        .set("secondsSpent", context.user.secondsSpent)
        .set("isInternal", context.user.coveoEmployee)
        .set("sfAccountName", account?.name ?? "")
        .set("isPartnerSfAccount", account?.partner ?? false);
    }
    return new Promise((resolve) => {
      context.amplitude?.identify(identify);
      resolve();
    });
  } else {
    return Promise.resolve();
  }
}

function getRootItemsAttributes(item: RestRootItem): Record<string, unknown> {
  const childItem = getChildItem(item);
  const childItemIsNotEvent = childItem?.type != ItemType.Event;
  return {
    item: {
      starred: item.starred,
      started: getCompletableItem(item)?.started,
      progress: getCompletableItem(item)?.progress,
      completed: getCompletableItem(item)?.completed,
      registered: getRegisterableItem(item)?.registered,
      attended: getRegisterableItem(item)?.attended,
      audiences: item.audiences,
      points: item.points,
      badges: item.badges,
      level: childItemIsNotEvent ? childItem?.level : undefined,
      levels: getParentItem(item)?.levels,
      solution: childItemIsNotEvent ? childItem?.solution : undefined,
      solutions: getParentItem(item)?.solutions,
      integration: childItemIsNotEvent ? childItem?.integration : undefined,
      integrations: getParentItem(item)?.integrations,
    },
  };
}

function logAmplitudeEvent(event: string, data: Record<string, unknown> = {}): void {
  if (context.amplitude != null) {
    context.amplitude.logEvent(event, data);
  }
}

function logSkillPathEvent(event: string, skillPath: RestSkillPath, data: Record<string, unknown> = {}): void {
  logAmplitudeEvent(
    event,
    Object.assign(
      {
        skillPathSlug: skillPath.slug,
      },
      data,
      getRootItemsAttributes(skillPath),
    ),
  );
}

function logRoadmapEvent(event: string, roadmap: RestRoadmap, data: Record<string, unknown> = {}): void {
  logAmplitudeEvent(
    event,
    Object.assign(
      {
        roadmapSlug: roadmap.slug,
      },
      data,
      getRootItemsAttributes(roadmap),
    ),
  );
}

function logCourseEvent(event: string, course: RestCourse, data: Record<string, unknown> = {}): void {
  logAmplitudeEvent(
    event,
    Object.assign(
      {
        courseSlug: course.slug,
      },
      data,
      getRootItemsAttributes(course),
    ),
  );
}

function logRootItemEvent(event: string, item: RestRootItem, data: Record<string, unknown> = {}): void {
  switch (item.type) {
    case ItemType.SkillPath:
      logSkillPathEvent(event, item, data);
      break;
    case ItemType.Roadmap:
      logRoadmapEvent(event, item, data);
      break;
    case ItemType.Course:
      logCourseEvent(event, item, data);
      break;
  }
}

function logLessonEvent(
  event: string,
  course: RestCourse,
  lesson: RestLesson,
  data: Record<string, unknown> = {},
): void {
  logAmplitudeEvent(
    event,
    Object.assign(
      {
        courseSlug: course.slug,
        lessonSlug: lesson.slug,
      },
      data,
      getRootItemsAttributes(course),
    ),
  );
}

function logEventCollectionEvent(
  event: string,
  restEventCollection: RestEventCollection,
  data: Record<string, unknown> = {},
): void {
  logAmplitudeEvent(
    event,
    Object.assign(
      {
        eventSlug: restEventCollection.slug,
      },
      data,
      getRootItemsAttributes(restEventCollection),
    ),
  );
}

function logEventEvent(event: string, restEvent: RestEvent, data: Record<string, unknown> = {}): void {
  logAmplitudeEvent(
    event,
    Object.assign(
      {
        eventSlug: restEvent.slug,
      },
      data,
      getRootItemsAttributes(restEvent),
    ),
  );
}

function logSessionEvent(
  event: string,
  restEvent: RestEvent,
  session: RestSession,
  data: Record<string, unknown> = {},
): void {
  logAmplitudeEvent(
    event,
    Object.assign(
      {
        eventSlug: restEvent.slug,
        sessionSlug: session.slug,
      },
      data,
      getRootItemsAttributes(restEvent),
    ),
  );
}

function logTeamEvent(
  event: string,
  team: RestTeam,
  members: RestTeamMember[],
  data: Record<string, unknown> = {},
): void {
  logAmplitudeEvent(
    event,
    Object.assign(
      {
        hashedTeamId: md5(team.teamId),
        hashedOwnerId: md5(team.ownerId),
        hashedName: md5(team.name),
        members: members.map((m) => (m.email.includes("@coveo.com") ? m.email : md5(m.email))),
      },
      data,
    ),
  );
}

export default {
  logAmplitudeEvent,
  logIdentify,
  logSkillPathEvent,
  logRoadmapEvent,
  logCourseEvent,
  logLessonEvent,
  logEventCollectionEvent,
  logEventEvent,
  logSessionEvent,
  logRootItemEvent,
  logTeamEvent,
  getRootItemsAttributes,
  viewEventsMap,
};
