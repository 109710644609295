
import { Component, Vue, Watch } from "vue-property-decorator";
import { loadSearchActions, loadSearchAnalyticsActions, SearchBoxState, Unsubscribe } from "@coveo/headless";
import { headless } from "@/headless";
import assert from "assert";
import amplitudeAnalytics from "@/amplitude-analytics";
import Magnifier from "@/assets/magnifier.svg";
import navigation from "@/navigation";

@Component({
  components: {
    Magnifier,
  },
})
export default class StandaloneSearchBox extends Vue {
  private headless = headless;
  private state: SearchBoxState | null = null;
  private query: string | null = null;
  private unsubscribe: Unsubscribe | null = null;
  private menuOpen = false;

  async created(): Promise<void> {
    if (headless == null) {
      return;
    }

    this.unsubscribe = headless.searchBox.subscribe(() => this.updateState());

    if (this.$route.path == navigation.getSearchUrl()) {
      this.query = this.getQueryFromHash();
      this.$nextTick(() => {
        assert(headless != null);
        headless.engine.dispatch(
          loadSearchActions(headless.engine).executeSearch(
            loadSearchAnalyticsActions(headless.engine).logInterfaceLoad(),
          ),
        );
      });
    } else {
      this.query = "";
    }
  }

  getQueryFromHash(): string {
    // Remove # from hash so it's read as query parameter
    const searchParams = new URLSearchParams(this.$route.hash.substring(1));
    return searchParams.get("q") ?? "";
  }

  destroyed(): void {
    if (this.unsubscribe != null) {
      this.unsubscribe();
    }
  }

  updateState(): void {
    assert(headless != null);
    this.state = headless.searchBox.state;
  }

  @Watch("$route")
  private clearWhenLeavingSearch() {
    if (this.$route.path !== navigation.getSearchUrl()) {
      this.query = null;
    }
  }

  @Watch("query")
  private updateQuery() {
    assert(headless != null);
    headless.searchBox.updateText(this.query ?? "");
  }

  private async triggerNewSearch() {
    if (headless == null) {
      return;
    }

    this.$nextTick(async () => {
      assert(headless != null);

      if (this.$route.path != navigation.getSearchUrl()) {
        await this.$router.push(navigation.getSearchUrl());
      }

      headless.searchBox.submit();
      amplitudeAnalytics.logAmplitudeEvent("performed search", { isSearchSuggestion: false });
    });
  }

  private async selectSuggestion(suggestion: string) {
    assert(headless != null);
    headless.searchBox.selectSuggestion(suggestion);
    amplitudeAnalytics.logAmplitudeEvent("performed search", { isSearchSuggestion: true });
    this.query = suggestion;

    if (this.$route.path != navigation.getSearchUrl()) {
      await this.$router.push(navigation.getSearchUrl());
    }
  }

  private toggleMenu(open: boolean) {
    window.setTimeout(() => (this.menuOpen = open), 150);
  }
}
