
import { Component, Prop, Vue } from "vue-property-decorator";
import { getAudienceCaption } from "@coveo/levelup-protocol";
import { AllowedReason, RestRootItem } from "@coveo/levelup-protocol";
import { Audience } from "@coveo/levelup-protocol";
import Target from "@/assets/target.svg";

@Component({
  components: { Target },
})
export default class ItemAudiences extends Vue {
  @Prop({ required: true }) private item!: RestRootItem;
  @Prop({ default: false }) small!: boolean;

  get audienceCaption(): string {
    if (this.item.allowedReason === AllowedReason.CoveoOnly) {
      return "Coveo Only";
    }

    if (Object.keys(Audience).every((k) => (this.item.audiences as string[]).includes(k))) {
      return "Everyone";
    }

    if (this.item.audiences.length > 1) {
      return this.item.audiences
        .filter((audience) => Object.keys(Audience).includes(audience))
        .map((audience) => getAudienceCaption(audience))
        .sort()
        .join(", ");
    }
    return getAudienceCaption(this.item.audiences[0]);
  }
}
