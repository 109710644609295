
import { Component, Vue } from "vue-property-decorator";
import { calls, context } from "@/globals";
import navigation from "@/navigation";
import { PromotionalBannerTheme, RestPromotionalBanner } from "@coveo/levelup-protocol";
import { RestRootItem } from "@coveo/levelup-protocol";
import "../../styles/banner-themes.scss";
import assert from "assert";

@Component
export default class PromotionalBanner extends Vue {
  bannerKey = 0;
  activeBanner!: RestPromotionalBanner | null;
  shouldShowBanner = false;

  async created(): Promise<void> {
    await this.renderBanner();

    if (this.shouldShowBanner) {
      await calls.incrementUserBannerImpression();
    }
  }

  private async renderBanner(): Promise<void> {
    this.activeBanner = await calls.getActivePromotionalBannerView();

    this.shouldShowBanner = !context.config.authoring && this.activeBanner !== null ? true : false;
    if (this.shouldShowBanner) {
      await calls.createUserBannerImpression();
    }
  }

  async clickBannerCta() {
    await calls.clickPromotionalBanner();
    this.shouldShowBanner = false;
    assert(this.activeBanner != null);
    const item: RestRootItem = await calls.getRootItem(this.activeBanner.itemId);
    this.$router.push(navigation.getRootItemViewUrl(item));
  }

  async closeBanner() {
    await calls.dismissPromotionalBanner();
    this.shouldShowBanner = false;
  }

  bannerThemeClass(bannerTheme: PromotionalBannerTheme) {
    switch (bannerTheme) {
      case PromotionalBannerTheme.WhiteEbonyClay:
        return "white-ebony-clay";
      case PromotionalBannerTheme.EbonyClayPomegranate:
        return "ebony-clay-pomegranate";
      case PromotionalBannerTheme.GrapeWhite:
        return "grape-white";
      case PromotionalBannerTheme.GovernorBayWhite:
        return "governor-bay-white";
      case PromotionalBannerTheme.BlackHazeGovernorBay:
        return "black-haze-governor-bay";
      case PromotionalBannerTheme.TropicalRainForestWhite:
        return "tropical-rain-forest-white";
      case PromotionalBannerTheme.WhiteTropicalRainForest:
        return "white-tropical-rain-forest";
      case PromotionalBannerTheme.GrapeCerulean:
      default:
        return "grape-cerulean";
    }
  }
}
