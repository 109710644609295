
import { Component, Vue } from "vue-property-decorator";
import { ErrorCode, RestError } from "@coveo/levelup-protocol";
import { calls } from "@/globals";
import CoveoDialog from "@/components/CoveoDialog.vue";

@Component({
  components: { CoveoDialog },
})
export default class ErrorModal extends Vue {
  private ErrorCode = ErrorCode;
  private error: RestError | null = null;

  created(): void {
    calls.axios.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err.response == null) {
          this.error = {
            name: ErrorCode.UnknownError,
            message: "An unknown error has occurred",
            stack: null,
          };
        } else if (
          err.response.status === 500 &&
          err.response.data != null &&
          err.response.data.startsWith != null &&
          err.response.data.startsWith("Proxy error")
        ) {
          // This is when the backend is restarting, we can let it go
          return Promise.reject(err);
        } else if (err.response.status === 503 && err.request.responseURL.indexOf("search/config") != -1) {
          // This is when search is disabled, we can let it go
          return Promise.reject(err);
        } else if (err.response.status >= 400 && err.response.status < 600) {
          const contentType = err.response.headers["content-type"];
          if (contentType != null && contentType.startsWith("application/json")) {
            this.error = err.response.data;
          } else {
            this.error = {
              name: ErrorCode.UnknownError,
              message: "An unknown error has occurred",
              stack: null,
            };
          }
        } else {
          return Promise.reject(err);
        }
      },
    );
  }
}
