
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class InfoBox extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: false }) icon!: Vue;
  @Prop({ default: false }) overflowAuto!: boolean;
  @Prop({ default: "white" }) background!: "white" | "gray";
  @Prop({ default: false }) flat!: boolean;
  @Prop({ default: false }) smallHeader!: boolean;
}
