
import { Component, Prop, Vue } from "vue-property-decorator";
import { RestRootItem } from "@coveo/levelup-protocol";
import { DateTime } from "luxon";
import { context } from "@/globals";

@Component
export default class ItemPin extends Vue {
  @Prop({ default: false }) showPin!: boolean;
  @Prop({ required: true }) item!: RestRootItem;
  authoring = context.config.authoring;

  get pinnedItemExpired(): boolean {
    return this.item.pinnedExpirationDate == null || DateTime.fromISO(this.item.pinnedExpirationDate) < DateTime.utc();
  }
}
