
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Avatars extends Vue {
  @Prop({ required: true }) avatars!: string[];
  @Prop({ default: 50 }) width!: number;

  get iconStyle(): Record<string, unknown> {
    return {
      width: `${this.width}px`,
      marginRight: `${-this.width / 2.5}px`,
    };
  }

  get moreMembersStyle(): Record<string, unknown> {
    return {
      width: `${this.width}px`,
      height: `${this.width}px`,
      marginRight: `${-this.width / 2.5}px`,
    };
  }
}
