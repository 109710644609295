
import { Component, Prop, Vue } from "vue-property-decorator";
import { getItemTypeCaption, RestRoadmap, RestSkillPath, RestCourse, isCourse } from "@coveo/levelup-protocol";
import navigation from "@/navigation";
import ItemCompletion from "@/components/items/ItemCompletion.vue";
import ItemBadge from "@/components/items/ItemBadge.vue";
import ItemStar from "@/components/items/ItemStar.vue";
import ItemTags from "@/components/items/ItemTags.vue";
import ItemLevel from "@/components/items/ItemLevel.vue";
import ItemPoints from "@/components/items/ItemPoints.vue";
import ItemTime from "@/components/items/ItemTime.vue";
import ItemAudiences from "@/components/items/ItemAudiences.vue";
import ItemLock from "@/components/items/ItemLock.vue";
import ItemCardFlag from "@/components/items/ItemCardFlag.vue";
import { localizeStringByContext } from "@coveo/levelup-protocol";
import { context } from "@/globals";
import MinifiedItemCard from "./MinifiedItemCard.vue";

@Component({
  components: {
    ItemLock,
    ItemCompletion,
    ItemBadge,
    ItemStar,
    ItemTags,
    ItemLevel,
    ItemPoints,
    ItemTime,
    ItemAudiences,
    ItemCardFlag,
    MinifiedItemCard,
  },
})
export default class CollapsedItemCard extends Vue {
  @Prop({ required: true }) item!: RestRoadmap | RestSkillPath | RestCourse;
  @Prop({ required: true }) assignedToUser!: boolean;
  @Prop({ default: false }) draggable!: boolean;
  context = context;
  expand = 0;
  getItemTypeCaption = getItemTypeCaption;
  isCourse = isCourse;
  localizeString = localizeStringByContext;

  created(): void {
    this.expand = this.item.started ? 0 : 1;
  }

  get cardColor(): string {
    if (this.item.archived) {
      return "gray60";
    } else if (this.item.completed) {
      return "neutral1Shade4";
    } else {
      return "neutral1Shade7";
    }
  }

  get viewUrl(): string {
    return navigation.getRootItemViewUrl(this.item);
  }
}
