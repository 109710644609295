
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class UserAvatar extends Vue {
  @Prop({ required: true }) private avatar!: string;
  @Prop({ default: 8 }) private diameterInRem!: number;

  private get imgStyle(): Record<string, unknown> {
    return {
      height: `${this.diameterInRem}rem`,
      width: `${this.diameterInRem}rem`,
      top: `${this.diameterInRem * 0.025}rem`,
      left: `${this.diameterInRem * 0.025}rem`,
    };
  }

  private get borderStyle(): Record<string, unknown> {
    return {
      height: `${this.diameterInRem * 1.05}rem`,
      width: `${this.diameterInRem * 1.05}rem`,
    };
  }
}
