
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Toast extends Vue {
  @Prop({ required: true }) icon!: Vue | string;
  @Prop({ required: true }) color!: string;
  @Prop({ required: true }) message!: string;
  @Prop({ required: true }) timeout!: number;
  // The visibility must change after mounting the component, otherwise there's no entrance transition
  private visible = false;

  mounted(): void {
    this.visible = true;
  }

  @Watch("visible")
  private emitClose() {
    if (!this.visible) {
      this.$emit("close");
    }
  }

  private get iconType(): "string" | "component" {
    return typeof this.icon === "string" ? "string" : "component";
  }
}
