
import { Component, Prop, Vue } from "vue-property-decorator";
import Calendar from "@/assets/calendar.svg";
import { RestEvent } from "@coveo/levelup-protocol";
import format from "@/format";
import { DateTime } from "luxon";

@Component({
  components: {
    Calendar,
  },
})
export default class EventDate extends Vue {
  private format = format;
  private DateTime = DateTime;
  @Prop({ required: true }) event!: RestEvent;
  @Prop({ default: false }) small!: boolean;
}
