import { RestLesson } from "@coveo/levelup-protocol";
import assert from "assert";
import Toast from "@/components/Toast.vue";
import vuetify from "@/plugins/vuetify";
import Confirm from "@/components/Confirm.vue";
import { Vue } from "vue-property-decorator";
import CompletionCheckIcon from "@/assets/completion-check.svg";

async function toast(color: string, icon: Vue | string, message: string, timeout?: number): Promise<void> {
  return new Promise<void>((resolve) => {
    const component = new Toast({
      vuetify,
      propsData: {
        color: color,
        icon: icon,
        message: message,
        timeout: timeout ?? 4000,
      },
    });

    component.$on("close", () => {
      resolve();
      component.$nextTick(() => component.$destroy());
    });

    const app = document.querySelector(".v-application");
    assert(app != null);
    app.appendChild(component.$mount().$el);
  });
}

async function confirm(color: string, icon: string, message: string): Promise<boolean> {
  return new Promise((resolve) => {
    const component = new Confirm({
      vuetify,
      propsData: {
        color: color,
        icon: icon,
        message: message,
      },
    });

    component.$on("confirm", () => {
      resolve(true);
      component.$nextTick(() => component.$destroy());
    });

    component.$on("cancel", () => {
      resolve(false);
      component.$nextTick(() => component.$destroy());
    });

    const app = document.querySelector(".v-application");
    assert(app != null);
    app.appendChild(component.$mount().$el);
  });
}

export default {
  notifyQuizFailed: async function (wrongAnswers: number): Promise<void> {
    await toast(
      "error",
      "mdi-skull",
      `Oh no! You have <b>${wrongAnswers}</b> wrong answer${wrongAnswers > 1 ? "s" : ""}`,
      1500,
    );
  },

  notifyLessonCompleted: async function (lesson: RestLesson): Promise<void> {
    await toast(
      "success",
      CompletionCheckIcon,
      `Lesson completed, you earned ${lesson.points} point${lesson.points > 1 ? "s" : ""}!`,
      1500,
    );
  },

  notifyInfo: async function (message: string, timeout?: number): Promise<void> {
    await toast("success", "mdi-information", message, timeout);
  },

  notifyWarning: async function (message: string, timeout?: number): Promise<void> {
    await toast("warning", "mdi-alert", message, timeout);
  },

  notifyError: async function (message: string, timeout?: number): Promise<void> {
    await toast("error", "mdi-alert-circle", message, timeout);
  },

  acknowledge: async function (message: string): Promise<boolean> {
    return await confirm("primary", "mdi-information", message);
  },

  confirmMildlyDangerousOperation: async function (message: string): Promise<boolean> {
    return await confirm("warning", "mdi-alert", message);
  },

  confirmReallyDangerousOperation: async function (message: string): Promise<boolean> {
    return await confirm("error", "mdi-alert-circle", message);
  },
};
