
import { EventBus } from "@/event-bus";
import { context, calls } from "@/globals";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Language } from "@coveo/levelup-protocol";

@Component
export default class LanguageToggle extends Vue {
  @Prop({ default: true }) onlyAuthoring!: boolean;
  context = context;
  Language = Language;

  get currentLanguage(): Language {
    if (context.user != null) {
      return context.user.language;
    } else {
      return (window.localStorage.getItem("language") as Language) ?? Language.English;
    }
  }

  async changeLanguage(l: Language): Promise<void> {
    if (context.user != null) {
      await calls.setUserLanguage(l);
    } else {
      window.localStorage.setItem("language", l);
    }
    await EventBus.emitUserChange();
    await EventBus.emitLanguageChange();
  }
}
