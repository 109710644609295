
import { Component, Vue } from "vue-property-decorator";
import { context } from "@/globals";
import UserAvatar from "@/components/users/UserAvatar.vue";

@Component({
  components: { UserAvatar },
})
export default class UserInfo extends Vue {
  private user = context.user;
}
