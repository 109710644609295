var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.shouldShowBanner)?_c('v-system-bar',{key:_vm.bannerKey,class:[_vm.bannerThemeClass(_vm.activeBanner.theme), 'banner'],attrs:{"app":"","height":"128px"}},[_c('v-btn',{staticClass:"mt-4 mr-n4",attrs:{"x-small":"","fab":"","absolute":"","top":"","right":"","icon":""}},[_c('v-icon',{staticClass:"dismiss-icon-color pa-0",on:{"click":_vm.closeBanner}},[_vm._v("mdi-close")])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-space-between"},[_c('v-col',{attrs:{"cols":"6","md":"8","lg":"9"}},[_c('div',{class:[
            'title-text-color',
            'title-text',
            { 'text-h5': _vm.$vuetify.breakpoint.smAndUp, 'text-subtitle-2': _vm.$vuetify.breakpoint.xsOnly },
          ]},[_vm._v(" "+_vm._s(_vm.activeBanner?.titleText)+" ")]),_c('div',{class:[
            'body-text-color',
            'body-text',
            { 'text-subtitle-1': _vm.$vuetify.breakpoint.smAndUp, 'text-caption': _vm.$vuetify.breakpoint.xsOnly },
          ]},[_vm._v(" "+_vm._s(_vm.activeBanner?.bodyText)+" ")])]),_c('v-col',[_c('v-btn',{staticClass:"cta-button-color",attrs:{"default":_vm.$vuetify.breakpoint.smAndDown,"x-large":_vm.$vuetify.breakpoint.mdAndUp},on:{"click":function($event){return _vm.clickBannerCta()}}},[_vm._v(_vm._s(_vm.activeBanner?.ctaLabel))])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }