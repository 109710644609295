
import { Component, Prop, Vue } from "vue-property-decorator";
import { RestRootItem } from "@coveo/levelup-protocol";
import { Result } from "@coveo/headless";
import SmallItemCard from "@/components/items/SmallItemCard.vue";
import LargeItemCard from "@/components/items/LargeItemCard.vue";
import MinifiedItemCard from "@/components/items/MinifiedItemCard.vue";
import CollapsedItemCard from "@/components/items/CollapsedItemCard.vue";
import { RestAssignment, userIsAssigned } from "@coveo/levelup-protocol";
import { calls } from "@/globals";

@Component({
  components: { SmallItemCard, LargeItemCard, MinifiedItemCard, CollapsedItemCard },
})
export default class ListOfRootItems extends Vue {
  @Prop({ required: true }) items!: RestRootItem[];
  @Prop({ default: false }) search!: boolean;
  @Prop({ default: () => [] }) results!: Result[];
  @Prop({ default: "small" }) private size!: "minified" | "small" | "large" | "collapsed";
  userAssignments: RestAssignment[] = [];
  userIsAssigned = userIsAssigned;

  private async created(): Promise<void> {
    this.userAssignments = (await calls.listAssignments()).assignments;
  }

  get showLargeCards(): boolean {
    return this.size === "large" && this.results.length === 0;
  }

  get showMinifiedCards(): boolean {
    return this.size === "minified" && this.results.length === 0;
  }

  get showSmallCards(): boolean {
    return this.size === "small" && this.results.length === 0;
  }

  get showCollapsedCards(): boolean {
    return this.size === "collapsed" && this.results.length === 0;
  }

  getColumns(breakpoint: "xs" | "sm" | "md" | "lg" | "xl"): number {
    if (this.showCollapsedCards) {
      return 12;
    }

    switch (breakpoint) {
      case "xs":
        return 12;
      case "sm":
        return this.showLargeCards ? 12 : 6;
      default:
        return this.showLargeCards ? 6 : 4;
    }
  }
}
