
import { Component, Prop, Vue } from "vue-property-decorator";
import { RestRootItem, getItemTypeCaption, isEvent } from "@coveo/levelup-protocol";
import {
  getCategoryCaption,
  getInternalContentTypeCaption,
  getSolutionCaption,
  getIntegrationCaption,
} from "@coveo/levelup-protocol";
import AssignedToYouIcon from "@/assets/assigned-to-you.svg";
import { calls, context } from "@/globals";
import { localizeStringByContext } from "@coveo/levelup-protocol";

@Component({
  components: { AssignedToYouIcon },
})
export default class ItemTags extends Vue {
  @Prop({ required: true }) item!: RestRootItem;
  @Prop({ required: true }) assignedToUser!: boolean;
  eventCollectionTags: string[] = [];
  getItemTypeCaption = getItemTypeCaption;
  getItemCategoryCaption = getCategoryCaption;
  getInternalContentTypeCaption = getInternalContentTypeCaption;
  getSolutionCaption = getSolutionCaption;
  getIntegrationCaption = getIntegrationCaption;
  isEvent = isEvent;
  private localizeString = localizeStringByContext;

  async created(): Promise<void> {
    if (isEvent(this.item)) {
      this.eventCollectionTags = await this.retrieveEventCollectionTags();
    }
  }

  get tagStyleClasses(): string {
    return "tag small mr-1 mb-1";
  }

  private async retrieveEventCollectionTags(): Promise<string[]> {
    return (await calls.getEventParents(this.item.id)).map((c) => this.localizeString(c.cardTag, context.user));
  }
}
