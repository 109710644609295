
import { Component, Prop, Vue } from "vue-property-decorator";
import { ItemType, RestRootItem, isCourse, isEvent } from "@coveo/levelup-protocol";
import navigation from "@/navigation";
import ItemCompletion from "@/components/items/ItemCompletion.vue";
import ItemBadge from "@/components/items/ItemBadge.vue";
import ItemStar from "@/components/items/ItemStar.vue";
import ItemTags from "@/components/items/ItemTags.vue";
import ItemLevel from "@/components/items/ItemLevel.vue";
import ItemPoints from "@/components/items/ItemPoints.vue";
import ItemTime from "@/components/items/ItemTime.vue";
import ItemAudiences from "@/components/items/ItemAudiences.vue";
import EventDate from "@/components/items/EventDate.vue";
import ItemCardFlag from "@/components/items/ItemCardFlag.vue";
import { buildInteractiveResult, HighlightUtils, InteractiveResult, Result, HighlightKeyword } from "@coveo/headless";
import { headless } from "@/headless";
import assert from "assert";
import { context } from "@/globals";
import ItemLock from "@/components/items/ItemLock.vue";
import { localizeStringByContext } from "@coveo/levelup-protocol";
import { decodeHTML } from "entities";

@Component({
  components: {
    ItemLock,
    ItemCompletion,
    ItemBadge,
    ItemStar,
    ItemTags,
    ItemLevel,
    ItemPoints,
    ItemTime,
    ItemAudiences,
    EventDate,
    ItemCardFlag,
  },
})
export default class SmallItemCard extends Vue {
  @Prop({ required: true }) assignedToUser!: boolean;
  @Prop({ required: true }) item!: RestRootItem;
  @Prop({ default: undefined }) result!: Result | undefined;
  @Prop({ required: false }) search!: boolean;

  context = context;
  decodeHTML = decodeHTML;
  isCourse = isCourse;
  isEvent = isEvent;
  localizeString = localizeStringByContext;
  locked!: boolean;

  private interactiveResult: InteractiveResult | null = null;

  created(): void {
    if (this.result !== undefined) {
      assert(headless != null);
      this.interactiveResult = buildInteractiveResult(headless.engine, { options: { result: this.result } });
    }

    this.locked = this.item.locked && !(context.user != null && context.user.coveoEmployee);
  }

  get completed(): boolean {
    return this.item.type === ItemType.Event || this.item.type === ItemType.EventCollection
      ? false
      : this.item.completed;
  }

  get viewUrl(): string {
    return navigation.getRootItemViewUrl(this.item);
  }

  formatHighlightedText(originalText: string, highlights: HighlightKeyword[]): string {
    const openingDelimiter = "_openingDelimiter_";
    const closingDelimiter = "_closingDelimiter_";

    const highlightedValue = HighlightUtils.highlightString({
      content: originalText,
      highlights: highlights,
      openingDelimiter: openingDelimiter,
      closingDelimiter: closingDelimiter,
    });

    return this.sanitize(highlightedValue)
      .replace(new RegExp(openingDelimiter, "g"), "<strong>")
      .replace(new RegExp(closingDelimiter, "g"), "</strong>");
  }

  onCardClick(): void {
    if (this.locked) {
      return;
    }

    if (this.result) {
      this.selectResult();
    }
    this.$router.push(this.viewUrl);
  }

  // Copied from packages/atomic/src/utils/xss-utils.ts...
  private sanitize(string: string) {
    const map: Record<string, string> = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#x27;",
      "/": "&#x2F;",
      "`": "&#x60;",
    };
    const reg = /[&<>"'/]/gi;
    return string.replace(reg, (match) => map[match]);
  }
  selectResult(): void {
    assert(this.interactiveResult != null);
    this.interactiveResult.select();
  }
}
