
import { Component, Vue } from "vue-property-decorator";
import TeamCard from "@/components/teams/TeamCard.vue";
import StandaloneSearchBox from "@/components/search/StandaloneSearchBox.vue";
import CoveoLogo from "@/components/navbar/CoveoLogo.vue";
import CoveoLinks from "@/components/navbar/CoveoLinks.vue";
import { RestUserTeam } from "@coveo/levelup-protocol";
import { EventWatcher } from "@/event-bus";
import { calls, context } from "@/globals";
import navigation, { ReportType } from "@/navigation";
import UserInfo from "@/components/navbar/UserInfo.vue";
import PromotionalBanner from "./PromotionalBanner.vue";

@Component({
  components: { UserInfo, CoveoLogo, TeamCard, StandaloneSearchBox, CoveoLinks, PromotionalBanner },
})
export default class MobileHeader extends Vue {
  context = context;
  navigation = navigation;
  drawer = false;
  ReportType = ReportType;
  teams: RestUserTeam[] = [];
  private watcher = new EventWatcher();
  private authoringPathRx = new RegExp(/^(\/authoring)(\/branches\/[\w-]+)?/);

  async created(): Promise<void> {
    if (context.user != null) {
      this.watcher.watchTeams(() => this.reloadTeamData());
      await this.reloadTeamData();
    }
  }

  private async reloadTeamData(): Promise<void> {
    if (context.user != null) {
      this.teams = (await calls.listUserTeams(context.user.userId)).teams;
    }
  }

  get enterAuthoringUrl(): string {
    return `${window.location.origin}/authoring${this.$route.path}`;
  }

  get exitAuthoringUrl(): string {
    const match = this.$route.path.match(this.authoringPathRx);
    if (match != null) {
      return `${window.location.origin}${this.$route.path.replace(match[0], "")}`;
    }
    return `${window.location.origin}${this.$route.path}`;
  }

  emitShowPromotionalBanner(showPromotionalBanner: boolean) {
    this.$emit("showPromotionalBanner", showPromotionalBanner);
  }
}
